import React from "react";
import { motion } from "framer-motion";

function PrizeTable() {
  const currency = "GHS";

  const winnings = [
    { betAmount: 5, miniWin: 5, smallWin: 10, mediumWin: 20, largeWin: 50, megaWin: '10,000' },
    { betAmount: 10, miniWin: 10, smallWin: 20, mediumWin: 40, largeWin: 100, megaWin: '20,000' },
    { betAmount: 20, miniWin: 20, smallWin: 40, mediumWin: 80, largeWin: 200, megaWin: '30,000' },
    { betAmount: 50, miniWin: 50, smallWin: 100, mediumWin: 200, largeWin: 500, megaWin: '40,000' },
    { betAmount: 100, miniWin: 100, smallWin: 200, mediumWin: 400, largeWin: 1000, megaWin: '50,000' },
    { betAmount: 200, miniWin: 200, smallWin: 400, mediumWin: 800, largeWin: 2000, megaWin: '80,000' },
    { betAmount: 500, miniWin: 500, smallWin: 1000, mediumWin: 2000, largeWin: 5000, megaWin: '100,000' },
    { betAmount: 1000, miniWin: 1000, smallWin: 2000, mediumWin: 4000, largeWin: 10000, megaWin: '200,000' },
    { betAmount: 2000, miniWin: 2000, smallWin: 4000, mediumWin: 8000, largeWin: 20000, megaWin: '500,000' },
  ];

  return (
    <div className="md:mx-40 mt-3">
      <h3 className="text-center text-[#EFD05C] md:text-xl text-sm font-bold mb-4">
        Cash Prizes Table Estimates
      </h3>
      <div className="overflow-x-auto">
        <div className="border-2 border-[#05358fc4] rounded-lg w-full">
          <table className="table-auto w-full text-white text-center">
            <thead className="bg-[#171c77cf] text-white">
              <tr>
                <th scope="col" className="py-2 md:py-3 text-xs md:text-sm lg:text-base">Play Amount ({currency})</th>
                <th scope="col" className="py-2 md:py-3 text-xs md:text-sm lg:text-base">Mega Win ({currency})</th>
                <th scope="col" className="py-2 md:py-3 text-xs md:text-sm lg:text-base">Large Win ({currency})</th>
                <th scope="col" className="py-2 md:py-3 text-xs md:text-sm lg:text-base">Medium Win ({currency})</th>
                <th scope="col" className="py-2 md:py-3 text-xs md:text-sm lg:text-base">Small Win ({currency})</th>
                <th scope="col" className="py-2 md:py-3 text-xs md:text-sm lg:text-base">Draw ({currency})</th>
              </tr>
            </thead>
            <tbody>
              {winnings.map((win, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? 'bg-[#010c2b]' : 'bg-[#071e5f]'
                  }`}
                >
                  <td className="px-2 md:px-3 py-2 md:py-3 text-xs md:text-sm lg:text-base">{win.betAmount}</td>

                  {/* Animate Mega Win with glow, scale, and bounce */}
                  <td className="px-2 md:px-3 py-2 md:py-3">
                    <motion.span
                      initial={{ scale: 1, y: 0 }}
                      animate={{
                        scale: [1, 1.1, 1], // Slight scaling effect
                        y: [0, -5, 0], // Subtle vertical bounce
                        textShadow: [
                          "0px 0px 0px rgba(255, 255, 0, 0.5)",
                          "0px 0px 8px rgba(255, 255, 0, 0.8)",
                          "0px 0px 0px rgba(255, 255, 0, 0.5)"
                        ], // Glowing effect
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: "loop"
                      }}
                      className="font-bold text-yellow-400 text-sm md:text-base lg:text-lg"
                    >
                      {win.megaWin}
                    </motion.span>
                  </td>

                  <td className="px-2 md:px-3 py-2 md:py-3 text-xs md:text-sm lg:text-base">{win.largeWin}</td>
                  <td className="px-2 md:px-3 py-2 md:py-3 text-xs md:text-sm lg:text-base">{win.mediumWin}</td>
                  <td className="px-2 md:px-3 py-2 md:py-3 text-xs md:text-sm lg:text-base">{win.smallWin}</td>
                  <td className="px-2 md:px-3 py-2 md:py-3 text-xs md:text-sm lg:text-base">{win.miniWin}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PrizeTable;





// import React from "react";
// import { motion } from "framer-motion";

// function PrizeTable() {
//   const currency = "GHS";

//   const winnings = [
//     { betAmount: 5, miniWin: 5, smallWin: 10, mediumWin: 20, largeWin: 50, megaWin: '10,000' },
//     { betAmount: 10, miniWin: 10, smallWin: 20, mediumWin: 40, largeWin: 100, megaWin: '20,000' },
//     { betAmount: 20, miniWin: 20, smallWin: 40, mediumWin: 80, largeWin: 200, megaWin: '30,000' },
//     { betAmount: 50, miniWin: 50, smallWin: 100, mediumWin: 200, largeWin: 500, megaWin: '40,000' },
//     { betAmount: 100, miniWin: 100, smallWin: 200, mediumWin: 400, largeWin: 1000, megaWin: '50,000' },
//     { betAmount: 200, miniWin: 200, smallWin: 400, mediumWin: 800, largeWin: 2000, megaWin: '80,000' },
//     { betAmount: 500, miniWin: 500, smallWin: 1000, mediumWin: 2000, largeWin: 5000, megaWin: '100,000' },
//     { betAmount: 1000, miniWin: 1000, smallWin: 2000, mediumWin: 4000, largeWin: 10000, megaWin: '200,000' },
//     { betAmount: 2000, miniWin: 2000, smallWin: 4000, mediumWin: 8000, largeWin: 20000, megaWin: '500,000' },
//   ];

//   return (
//     <div className="md:mx-40 mt-3">
//       <h3 className="text-center text-[#EFD05C] md:text-xl text-sm font-bold mb-4">
//         Cash Prizes Table Estimates
//       </h3>
//       <div className="flex md:text-sm justify-center text-xs">
//         <div className="border-2 border-[#05358fc4] rounded-lg w-full overflow-hidden">
//           <table className="w-full text-white text-center">
//             <thead className="bg-[#171c77cf] text-white">
//               <tr>
//                 <th scope="col" className="py-3">Play Amount ({currency})</th>
//                 <th scope="col" className=" ">Mega Win ({currency})</th>
//                 <th scope="col" className=" ">Large Win ({currency})</th>
//                 <th scope="col" className=" ">Medium Win ({currency})</th>
//                 <th scope="col" className=" ">Small Win ({currency})</th>
//                 <th scope="col" className="">Draw ({currency})</th>
//               </tr>
//             </thead>
//             <tbody>
//               {winnings.map((win, index) => (
//                 <tr
//                   key={index}
//                   className={`${
//                     index % 2 === 0 ? 'bg-[#010c2b]' : 'bg-[#071e5f]'
//                   }`}
//                 >
//                   <td className="px-3 py-3">{win.betAmount}</td>

//                   {/* Animate Mega Win */}
//                   <td className="px-3 py-3">
//                     <motion.span
//                       initial={{ scale: 1 }}
//                       animate={{ scale: [1, 1.2, 1] }}
//                       transition={{
//                         duration: 1.5,
//                         repeat: Infinity,
//                         repeatType: "mirror"
//                       }}
//                       className="font-bold text-yellow-400 text-lg"
//                     >
//                       {win.megaWin}
//                     </motion.span>
//                   </td>

//                   <td className="px-3 py-3">{win.largeWin}</td>
//                   <td className="px-3 py-3">{win.mediumWin}</td>
//                   <td className="px-3 py-3">{win.smallWin}</td>
//                   <td className="px-3 py-3">{win.miniWin}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default PrizeTable;



// import React from "react";


// function PrizeTable() {
//   // Set the currency as a constant for easy modification if necessary
//   const currency = "GHS";

//   // Array of objects representing different betting amounts and corresponding winnings
//   const winnings = [
//     { betAmount: 5, miniWin: 5, smallWin: 10, mediumWin: 20, largeWin: 50, megaWin: '10,000' },
//     { betAmount: 10, miniWin: 10, smallWin: 20, mediumWin: 40, largeWin: 100, megaWin: '20,000' },
//     { betAmount: 20, miniWin: 20, smallWin: 40, mediumWin: 80, largeWin: 200, megaWin: '30,000' },
//     { betAmount: 50, miniWin: 50, smallWin: 100, mediumWin: 200, largeWin: 500, megaWin: '40,000' },
//     { betAmount: 100, miniWin: 100, smallWin: 200, mediumWin: 400, largeWin: 1000, megaWin: '50,000' },
//     { betAmount: 200, miniWin: 200, smallWin: 400, mediumWin: 800, largeWin: 2000, megaWin: '80,000' },
//     { betAmount: 500, miniWin: 500, smallWin: 1000, mediumWin: 2000, largeWin: 5000, megaWin: '100,000' },
//     { betAmount: 1000, miniWin: 1000, smallWin: 2000, mediumWin: 4000, largeWin: 10000, megaWin: '200,000' },
//     { betAmount: 2000, miniWin: 2000, smallWin: 4000, mediumWin: 8000, largeWin: 20000, megaWin: '500,000' },
//   ];

//   // JSX to render the table
//   return (
//     <div className=" md:mx-40  mt-3 ">
//       <h3 className="text-center text-[#EFD05C]
//  md:text-xl text-sm font-bold mb-4">
//             Cash Prizes Table Estimates
//           </h3>
//       <div className=" flex md:text-sm   justify-center text-xs">
//       <div className="border-2 border-[#05358fc4] rounded-lg w-full overflow-hidden">
//         <table className="   w-full text-white text-center">
//           <thead className="bg-[#171c77cf] text-white ">
//             <tr>
//               <th scope="col" className="py-3">Play Amount ({currency})</th>
//               <th scope="col" className=" ">Mega Win ({currency})</th>
//               <th scope="col" className=" ">Large Win ({currency})</th>
//               <th scope="col" className=" ">Medium Win ({currency})</th>
//               <th scope="col" className=" ">Small Win ({currency})</th>
//               <th scope="col" className="">Draw ({currency})</th>
//             </tr>
//           </thead>
//           <tbody>
//             {winnings.map((win, index) => (
//               <tr
//                 key={index}
//                 className={` ${
//                   index % 2 === 0 ? 'bg-[#010c2b]' : 'bg-[#071e5f]'
//                 }`}
//               >
//                 <td className="px-3">{win.betAmount}</td>
//                 <td className="font-bold">{win.megaWin}</td>
//                 <td className="px-3 py-3">{win.largeWin}</td>
//                 <td className="px-3 py-3">{win.mediumWin}</td>
//                 <td className="px-3 py-3">{win.smallWin}</td>
//                 <td className=" px-3">{win.miniWin}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default PrizeTable;
