function PrizeLevels() {
    const levels = [
      {
        title: "Mini win",
        description:
          "Enjoy frequent small rewards that keep the excitement alive with every play",
      },
      {
        title: "Small win",
        description:
          "Experience the thrill of bigger prizes that give you a taste of substantial wins.",
      },
      {
        title: "Medium win",
        description:
          "Aim for mid-range prizes that offer more generous rewards, perfect for boosting your winnings.",
      },
      {
        title: "Large win",
        description:
          "Go for the highest regular prize tier and enjoy significant rewards that make every play worthwhile.",
      },
    ];
  
    return (
      <div className="container mx-auto    lg:px-8 my-6">
        <div className="flex  justify-center ">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full lg:max-w-6xl md:max-w-xl  ">
            {levels.map((level, index) => (
              <div
                className="border-[3px] bg-white text-[#000058] rounded-xl p-4 text-center"
                key={index}>
                <h3 className=" font-bold  mb-2">{level.title}</h3>
                <p className=" text-sm md:text-md">
                  {level.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  
  export default PrizeLevels;