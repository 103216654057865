import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

// Logo placeholder component
const LogoPlaceholder = () => (
  <div className="w-16 h-16 sm:w-20 sm:h-20 rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
   <img src="megacash.png"/>
  </div>
);

const AgeVerification = ({ children }) => {
  const [ageVerified, setAgeVerified] = useState(null);
  const [loading, setLoading] = useState(true);
  const [age, setAge] = useState(null);

  useEffect(() => {
    const checkAgeVerification = async () => {
      const storedAgeVerified = localStorage.getItem("ageVerified");
      const storedAge = localStorage.getItem("age");

      if (storedAgeVerified === "true" && storedAge) {
        setAge(parseInt(storedAge, 10));
        setAgeVerified(true);
        setLoading(false);
      } else {
        setLoading(false);
        try {
          const result = await Swal.fire({
            html: `
              <div class="flex flex-col items-center">
                <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6  rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
                   <img src="megacash.png"/>
                </div>
                <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Welcome to Megacashbox</h2>
                <p class="mb-4 sm:mb-6 text-white text-center text-sm sm:text-base">You must be 18 or older to access this site. Please enter your age:</p>
              </div>
            `,
            input: "number",
            inputAttributes: {
              min: 0,
              max: 99,
              step: 1,
            },
            customClass: {
              container: "font-sans",
              popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",  
              input: "w-full p-2 sm:p-3 border rounded bg-white text-[#061126] text-base sm:text-lg box-border mx-auto",
              actions: "mt-4 sm:mt-6",
              confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
            },
            showCancelButton: false,
            confirmButtonText: "Continue",
            allowOutsideClick: false,
            allowEscapeKey: false,
            validationMessage: "Please enter a valid age",
          });

          if (result.isConfirmed) {
            const inputAge = parseInt(result.value);
            if (inputAge >= 18) {
              localStorage.setItem("ageVerified", "true");
              localStorage.setItem("age", inputAge.toString());
              setAge(inputAge);
              setAgeVerified(true);
              Swal.fire({
                html: `
                  <div class="flex flex-col items-center">
                    <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
                       <img src="megacash.png"/>
                    </div>
                    <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Welcome to Megacashbox!</h2>
                    <p class="text-white text-center text-sm sm:text-base">Thank you for verifying your age. Enjoy your visit!</p>
                  </div>
                `,
                icon: "success",
                confirmButtonText: "Let's Go!",
                customClass: {
                  container: "font-sans",
                  popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
                  confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
                },
              });
            } else {
              await Swal.fire({
                html: `
                  <div class="flex flex-col items-center">
                    <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6  rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
                       <img src="megacash.png"/>
                    </div>
                    <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Access Denied</h2>
                    <p class="text-white text-center text-sm sm:text-base">We're sorry, but you must be 18 or older to access Megacashbox.</p>
                  </div>
                `,
                icon: "error",
                confirmButtonText: "OK",
                customClass: {
                  container: "font-sans",
                  popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
                  confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              setAgeVerified(false);
            }
          } else {
            setAgeVerified(false);
          }
        } catch (error) {
          console.error("Error in age verification:", error);
          setAgeVerified(false);
        }
      }
    };

    checkAgeVerification();
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-[#061126] text-white p-4">
        <LogoPlaceholder />
        <h2 className="text-2xl sm:text-3xl font-bold my-4 text-center">Welcome to Megacashbox</h2>
        <p className="mb-4 text-center">Loading...</p>
        <div className="w-10 h-10 sm:w-12 sm:h-12 border-4 border-[#E14400] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (ageVerified === true) {
    return React.cloneElement(children, { age });
  }

  if (ageVerified === false) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-[#061126] text-white p-4">
        <LogoPlaceholder />
        <h2 className="text-2xl sm:text-3xl font-bold my-4 text-center">Access Denied</h2>
        <p className="text-center text-sm sm:text-base">We're sorry, but you must be 18 or older to access Megacashbox.</p>
      </div>
    );
  }

  return null;
};

export default AgeVerification;

// import React, { useEffect, useState } from "react";
// import Swal from "sweetalert2";

// // Logo placeholder component
// const LogoPlaceholder = () => (
//   <div className="w-16 h-16 sm:w-20 sm:h-20 bg-[#E14400] rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
//     MCB
//   </div>
// );

// const AgeVerification = ({ children }) => {
//   const [ageVerified, setAgeVerified] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [age, setAge] = useState(null);

//   useEffect(() => {
//     const checkAgeVerification = async () => {
//       const storedAgeVerified = localStorage.getItem("ageVerified");
//       const storedAge = localStorage.getItem("age");

//       if (storedAgeVerified === "true" && storedAge) {
//         setAge(parseInt(storedAge, 10));
//         setAgeVerified(true);
//         setLoading(false);
//       } else {
//         setLoading(false);
//         try {
//           const result = await Swal.fire({
//             html: `
//               <div class="flex flex-col items-center">
//                 <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 bg-[#E14400] rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
//                   MCB
//                 </div>
//                 <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Welcome to Megacashbox</h2>
//                 <p class="mb-4 sm:mb-6 text-white text-center text-sm sm:text-base">You must be 18 or older to access this site. Please enter your age:</p>
//               </div>
//             `,
//             input: "number",
//             inputAttributes: {
//               min: 0,
//               max: 99,
//               step: 1,
//             },
//             customClass: {
//               container: "font-sans",
//               popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
//               input: "w-full p-2 sm:p-3 border rounded bg-white text-[#061126] text-base sm:text-lg",
//               confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg mt-4 sm:mt-6 w-full sm:w-auto",
//             },
//             showCancelButton: false,
//             confirmButtonText: "Continue",
//             allowOutsideClick: false,
//             allowEscapeKey: false,
//             validationMessage: "Please enter a valid age",
//           });

//           if (result.isConfirmed) {
//             const inputAge = parseInt(result.value);
//             if (inputAge >= 18) {
//               localStorage.setItem("ageVerified", "true");
//               localStorage.setItem("age", inputAge.toString());
//               setAge(inputAge);
//               setAgeVerified(true);
//               Swal.fire({
//                 html: `
//                   <div class="flex flex-col items-center">
//                     <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 bg-[#E14400] rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
//                       MCB
//                     </div>
//                     <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Welcome to Megacashbox!</h2>
//                     <p class="text-white text-center text-sm sm:text-base">Thank you for verifying your age. Enjoy your visit!</p>
//                   </div>
//                 `,
//                 icon: "success",
//                 confirmButtonText: "Let's Go!",
//                 customClass: {
//                   container: "font-sans",
//                   popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
//                   confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg mt-4 sm:mt-6 w-full sm:w-auto",
//                 },
//               });
//             } else {
//               await Swal.fire({
//                 html: `
//                   <div class="flex flex-col items-center">
//                     <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 bg-[#E14400] rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
//                       MCB
//                     </div>
//                     <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Access Denied</h2>
//                     <p class="text-white text-center text-sm sm:text-base">We're sorry, but you must be 18 or older to access Megacashbox.</p>
//                   </div>
//                 `,
//                 icon: "error",
//                 confirmButtonText: "OK",
//                 customClass: {
//                   container: "font-sans",
//                   popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
//                   confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg mt-4 sm:mt-6 w-full sm:w-auto",
//                 },
//                 allowOutsideClick: false,
//                 allowEscapeKey: false,
//               });
//               setAgeVerified(false);
//             }
//           } else {
//             setAgeVerified(false);
//           }
//         } catch (error) {
//           console.error("Error in age verification:", error);
//           setAgeVerified(false);
//         }
//       }
//     };

//     checkAgeVerification();
//   }, []);

//   if (loading) {
//     return (
//       <div className="flex flex-col items-center justify-center min-h-screen bg-[#061126] text-white p-4">
//         <LogoPlaceholder />
//         <h2 className="text-2xl sm:text-3xl font-bold my-4 text-center">Welcome to Megacashbox</h2>
//         <p className="mb-4 text-center">Loading...</p>
//         <div className="w-10 h-10 sm:w-12 sm:h-12 border-4 border-[#E14400] border-t-transparent rounded-full animate-spin"></div>
//       </div>
//     );
//   }

//   if (ageVerified === true) {
//     return React.cloneElement(children, { age });
//   }

//   if (ageVerified === false) {
//     return (
//       <div className="flex flex-col items-center justify-center min-h-screen bg-[#061126] text-white p-4">
//         <LogoPlaceholder />
//         <h2 className="text-2xl sm:text-3xl font-bold my-4 text-center">Access Denied</h2>
//         <p className="text-center text-sm sm:text-base">We're sorry, but you must be 18 or older to access Megacashbox.</p>
//       </div>
//     );
//   }

//   return null;
// };

// export default AgeVerification;

