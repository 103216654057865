import { useState } from "react";
import Socials from "../ghana/socials";

import { Link, NavLink } from "react-router-dom";


const Nav = () => {
  let [open, setOpen] = useState(false);
  const Links = [
    { name: "HOME", link: "/" },               // Home link 
    { name: "PRIZES", link: "/prizes" },
    { name: "WINNERS", link: "/winners" },  
    { name: "HOW TO PLAY?", link: "/howtoplay" }, // How to Play link
    { name: "FAQ", link: "/faq" },
   
  ];

  return (
    <div className="font-body w-full  fixed top-0 left-0 z-10">
      <div className="md:flex  items-center justify-between shadow-2xl bg-[#061126]  ">

        <div className="flex flex-shrink-0 items-center   py-4 bg-[#061126] lg:bg-[#0A1937]  md:rounded-r-lg rounded-b-lg cursor-pointer md:px-8 px-5 md:py-5 ">
        <Link to="/">
          <img
            src="megacash.png"
            alt="MegaCash Logo"
            className="h-10 w-10 mr-1"
            />
            </Link>

          <div className="flex flex-col  ">
            <span className="text-[#D4AF37] font-bold text-md">MEGACASH</span>
            <span className="text-white mt-[-4px] text-[10px]">LUCKY BOX</span>
          </div>
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-4 cursor-pointer md:hidden">
          <ion-icon name={open ? "close-outline" : "menu-outline"}></ion-icon>
        </div>

        <ul
          className={`md:flex text-center  md:items-center md:pb-0 pb-12 absolute md:static md:bg-inherit bg-[#0a1129] md:z-auto  z-[-1] left-0 w-full md:w-auto md:pl-0  md:mr-6 transition-all duration-500 ease-in ${open ? "opacity-100" : "top-[-490px]"} md:opacity-100 opacity-95 whitespace-nowrap `}>
          {Links.map((link) => (
            <li
              key={link.name}
              className="md:ml-8 font-semibold text-[15px] md:mx-5 md:my-0 my-7">
              <NavLink
                to={link.link}
                className={({ isActive }) => 
                  isActive
                    ? "text-yellow-500 underline decoration-dashed"
                    : "text-white hover:text-amber-700 duration-200"}
                    onClick={() => setOpen(false)}>
                {link.name}
              </NavLink>
            </li>
          ))}



          <Socials />
        </ul>
      </div>
    </div>
  );
};

export default Nav;
