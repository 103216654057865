
import PrizeLevels from "../components/ghana/PrizeLevels";
import PrizeTable from "../components/ghana/PrizesTablev2";

function Prizes() {
  return (
    <div className="pt-20 px-5">
      
      <PrizeTable />
      <PrizeLevels />
      
    </div>
  );
}

export default Prizes;