import React from "react";


const PrivacyPolicy = () => {
  const sections = [
    {
      title: "Information We Collect",
      subsections: [
        {
          title: "Participant Information",
          items: [
            "Name",
            "Contact Information (Email Address, Phone Number)",
            "Mobile Money Account Details (for prize distribution)",
          ],
        },
        {
          title: "Demographic Information",
          items: ["Age", "Gender", "Location"],
        },
        {
          title: "Transaction Information",
          items: ["Payment details if applicable"],
        },
      ],
    },
    {
      title: "How We Use Collected Information",
      subsections: [
        {
          title: "Game Participation",
          content:
            "Use participant information for the purpose of Game entry and prize distribution.",
        },
        {
          title: "Marketing and Communication",
          content:
            "Send promotional updates, notifications, and marketing materials related to the Game promotion.",
        },
        {
          title: "Demographic Analysis",
          content:
            "Analyze aggregated demographic information to enhance the promotion's relevance.",
        },
        {
          title: "Legal Compliance",
          content:
            "Fulfill legal and regulatory requirements related to the promotion.",
        },
      ],
    },
    {
      title: "Information Sharing",
      subsections: [
        {
          title: "Third-Party Service Providers",
          content:
            "Share participant information with trusted third-party service providers strictly for promotion-related activities (e.g., prize distribution).",
        },
        {
          title: "Legal Compliance",
          content:
            "Disclose information as required by law or in response to a legal process.",
        },
      ],
    },
    {
      title: "Security Measures",
      subsections: [
        {
          title: "Data Encryption",
          content:
            "Employ encryption protocols to safeguard participant information during data transmission.",
        },
        {
          title: "Access Controls",
          content:
            "Restrict access to participant information to authorized personnel only.",
        },
        {
          title: "Data Security Audits",
          content:
            "Conduct regular security audits to identify and address potential vulnerabilities.",
        },
      ],
    },
    {
      title: "Participant Rights",
      subsections: [
        {
          title: "Access and Correction",
          content:
            "Participants have the right to access and correct their personal information.",
        },
        {
          title: "Withdrawal of Consent",
          content:
            "Participants can withdraw consent for data processing at any time.",
        },
      ],
    },
    {
      title: "Data Retention",
      subsections: [
        {
          title: "Participant Data",
          content:
            "Retain participant data for the duration of the promotion and for a reasonable period thereafter for legal and audit purposes.",
        },
      ],
    },
    {
      title: "Changes to Privacy Policy",
      subsections: [
        {
          title: "Policy Updates",
          content:
            "We reserve the right to update this Privacy Policy. Any changes will be communicated through our official communication channels.",
        },
      ],
    },
    {
      title: "Contact Information",
      content:
        "If you have any questions or concerns about this Privacy Policy, please contact us at 0244079441.\n\nBy participating in our Game promotion, you acknowledge that you have read and understood this Privacy Policy. Your continued participation constitutes acceptance of its terms.\n\nSparkhouse Promotions Ltd\nAccra Ghana\nSparkhousepromotions@gmail.com\n0244079441 / 0202339493",
    },
  ];

  return (
    <>
     
      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 text-white bg-[#16165c44] mt-10 ">
        <div className="max-w-7xl mx-auto">
          <h1
            className="text-4xl font-bold text-white text-center bg-cover bg-center   p-7"
            style={{
              backgroundImage: "url('privacy.svg')",
            }}>
            Privacy Policy
          </h1>

          {/* Introduction */}
          <div className="my-10 bg-[#2c2c4b41] border-b-2   p-6 shadow-lg mb-10">
            <p className="text-gray-200">
              At Sparkhouse Promotions Ltd, we are committed to ensuring the
              privacy and security of the personal information collected during
              our Game promotion. This Privacy Policy outlines our practices
              regarding the collection, use, and disclosure of personal
              information. By participating in our Game promotion, you consent
              to the terms outlined in this Privacy Policy.
            </p>
          </div>

          {/* Effective Date */}
          <div className="text-center mb-10">
            <p className="text-yellow-400">Effective Date: 01/09/2024</p>
          </div>

          <div className="flex flex-wrap justify-center gap-8 mb-10">
            {/* Information We Collect Box */}
            <div className="w-full md:w-5/12 bg-[#061126] border-2 border-[#05358F] rounded-lg p-6 shadow-lg">
              <h2 className="text-2xl font-semibold text-yellow-400 mb-4">
                Information We Collect
              </h2>
              <ul className="list-disc list-inside text-gray-200">
                <li>Name</li>
                <li>Contact Information (Email Address, Phone Number)</li>
                <li>Mobile Money Account Details (for prize distribution)</li>
                <li>Age</li>
                <li>Gender</li>
                <li>Location</li>
                <li>Payment details if applicable</li>
              </ul>
            </div>

            {/* How We Use Collected Information Box */}
            <div className="w-full md:w-5/12 bg-[#16165c44] border-2 border-[#00006d] rounded-lg p-6 shadow-lg">
              <h2 className="text-2xl font-semibold text-yellow-400 mb-4">
                How We Use Collected Information
              </h2>
              <ul className="list-disc list-inside text-gray-200">
                <li>
                  Game Participation: Use participant information for the
                  purpose of Game entry and prize distribution.
                </li>
                <li>
                  Marketing and Communication: Send promotional updates,
                  notifications, and marketing materials related to the Game
                  promotion.
                </li>
                <li>
                  Demographic Analysis: Analyze aggregated demographic
                  information to enhance the promotion's relevance.
                </li>
                <li>
                  Legal Compliance: Fulfill legal and regulatory requirements
                  related to the promotion.
                </li>
              </ul>
            </div>

            {sections.map((section, index) => (
              <div
                key={index}
                className="  pb-6  w-full md:w-5/12  bg-[#16165c44] border-2 border-[#00006d] rounded-lg p-6 shadow-lg">
                <h2 className="text-2xl font-semibold text-yellow-600 mb-4">
                  {section.title}
                </h2>
                {section.content && (
                  <p className="text-gray-400 mb-4 whitespace-pre-line">
                    {section.content}
                  </p>
                )}
                {section.subsections && (
                  <div className="space-y-4">
                    {section.subsections.map((subsection, subIndex) => (
                      <div key={subIndex}>
                        <h3 className="text-xl font-medium text-teal-500 mb-2">
                          {subsection.title}
                        </h3>
                        {subsection.content && (
                          <p className="text-gray-400">{subsection.content}</p>
                        )}
                        {subsection.items && (
                          <ul className="list-disc list-inside text-gray-400 pl-4">
                            {subsection.items.map((item, itemIndex) => (
                              <li key={itemIndex}>{item}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Additional sections can be added here if needed */}
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;