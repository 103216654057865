import { Routes, Route, Navigate } from 'react-router-dom';
import GhPageHub from './pages/GhPageHub';
import AgeVerification from './components/AgeVerification';
import Howtoplay from './pages/Howtoplay';
import Prizes from './pages/prizes';
import Winners from './pages/winner';
import FAQSection from './pages/faq';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/privacypolicy';
import Nav from './components/ghana/Nav';
import Footer from './components/ghana/footer';
import ComingSoon from './pages/comingSoon';

function App() {
  return (
    <div>
        <Nav/>
      <Routes>

        {/* AgeVerification will handle the logic for verifying age */}
        <Route path="/" element={<AgeVerification><GhPageHub /></AgeVerification>} index />
        

       <Route path="/gh" element={<AgeVerification><GhPageHub /></AgeVerification>} />

        {/* Add the How to Play route */}
        <Route path="/howtoplay" element={<AgeVerification><Howtoplay /></AgeVerification>} />

         {/* Add the How to Play route */}
         <Route path="/prizes" element={<AgeVerification><Prizes /></AgeVerification>} />

          {/* Add the How to Play route */}
          <Route path="/winners" element={ <AgeVerification><Winners /></AgeVerification>} />

            {/* Add the How to Play route */}
            <Route path="/faq" element={<AgeVerification><FAQSection /></AgeVerification>} />

            <Route path="/termsConditions" element={<AgeVerification><TermsAndConditions /></AgeVerification>} />

            <Route path="/privacypolicy" element={<AgeVerification><PrivacyPolicy /></AgeVerification>} />

            


        {/* Fallback to root */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    <Footer/>
    </div>
  );
}

export default App;
