import React from 'react';

const RewardsGrid = ({ rewards, userBox, onPlayAgain }) => {
  const boxGradients = [
    'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
    'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
    'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
    'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
    'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
    'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
  ];

  const parsedRewards = rewards.map((reward) => {
    const [boxString, amount] = reward.split(" - ");
    const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
    return { boxNumber, amount };
  });

  return (
    <div className="flex flex-col items-center">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 sm:gap-3 md:gap-4 my-5 w-full max-w-6xl mx-auto">
        {parsedRewards.map(({ boxNumber, amount }, index) => (
          <div
            key={index}
            className={`relative flex flex-col justify-center items-center p-2 sm:p-3 border-2 rounded-lg cursor-pointer ${boxGradients[index % boxGradients.length]} ${
              boxNumber === userBox 
                ? 'border-yellow-400 shadow-lg shadow-yellow-400/50 scale-105 z-10' 
                : 'border-transparent hover:scale-102'
            } transition-all duration-300 aspect-square`}
          >
            <span className={`text-base sm:text-lg md:text-xl lg:text-2xl font-bold text-center mb-1 ${
              boxNumber === userBox ? 'text-yellow-400' : 'text-white'
            }`}>
              Box {boxNumber}
            </span>
            <span className={`text-xs sm:text-sm md:text-base lg:text-lg text-center ${
              boxNumber === userBox ? 'text-yellow-400' : 'text-white'
            }`}>
              {amount}
            </span>
            {boxNumber === userBox && (
              <>
                <div className="absolute inset-0 border-4 border-yellow-400 rounded-lg animate-pulse"></div>
                <div className="absolute inset-0 bg-yellow-400 opacity-0 rounded-lg animate-glow"></div>
              </>
            )}
          </div>
        ))}
      </div>
      {/* <button
        onClick={onPlayAgain}
        className="mt-6 px-6 py-3 bg-[#E14400] text-white font-bold text-lg  hover:bg-[#FF5500] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E14400]"
      >
        Play Again
      </button> */}
      <style jsx>{`
        @keyframes glow {
          0%, 100% { opacity: 0; }
          50% { opacity: 0.3; }
        }
        .animate-glow {
          animation: glow 2s infinite;
        }
      `}</style>
    </div>
  );
};

export default RewardsGrid;
  
  
  //##########################################################  with  no play again
// import React from 'react';

// const RewardsGrid = ({ rewards, userBox }) => {
//   const boxGradients = [
//     'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
//     'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
//     'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
//     'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
//     'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
//     'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
//   ];

//   const parsedRewards = rewards.map((reward) => {
//     const [boxString, amount] = reward.split(" - ");
//     const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//     return { boxNumber, amount };
//   });

//   return (
//     <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 sm:gap-3 md:gap-4 my-5 w-full max-w-6xl mx-auto">
//       {parsedRewards.map(({ boxNumber, amount }, index) => (
//         <div
//           key={index}
//           className={`relative flex flex-col justify-center items-center p-2 sm:p-3 border-2 rounded-lg cursor-pointer ${boxGradients[index % boxGradients.length]} ${
//             boxNumber === userBox 
//               ? 'border-yellow-400 shadow-lg shadow-yellow-400/50 scale-105 z-10' 
//               : 'border-transparent hover:scale-102'
//           } transition-all duration-300 aspect-square`}
//         >
//           <span className={`text-base sm:text-lg md:text-xl lg:text-2xl font-bold text-center mb-1 ${
//             boxNumber === userBox ? 'text-yellow-400' : 'text-white'
//           }`}>
//             Box {boxNumber}
//           </span>
//           <span className={`text-xs sm:text-sm md:text-base lg:text-lg text-center ${
//             boxNumber === userBox ? 'text-yellow-400' : 'text-white'
//           }`}>
//             {amount}
//           </span>
//           {boxNumber === userBox && (
//             <>
//               <div className="absolute inset-0 border-4 border-yellow-400 rounded-lg animate-pulse"></div>
//               <div className="absolute inset-0 bg-yellow-400 opacity-0 rounded-lg animate-glow"></div>
//             </>
//           )}
//         </div>
//       ))}
//       <style jsx>{`
//         @keyframes glow {
//           0%, 100% { opacity: 0; }
//           50% { opacity: 0.3; }
//         }
//         .animate-glow {
//           animation: glow 2s infinite;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default RewardsGrid;

//  ############################################################### with animationn 
// import React from 'react';

// const RewardsGrid = ({ rewards, userBox }) => {
//   const boxGradients = [
//     'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
//     'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
//     'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
//     'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
//     'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
//     'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
//   ];

//   const parsedRewards = rewards.map((reward) => {
//     const [boxString, amount] = reward.split(" - ");
//     const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//     return { boxNumber, amount };
//   });

//   return (
//     <div className="grid grid-cols-2 gap-2 sm:gap-3 md:gap-4 my-5 w-full">
//       {parsedRewards.map(({ boxNumber, amount }, index) => (
//         <div
//           key={index}
//           className={`relative flex flex-col justify-center items-center p-2 sm:p-3 md:p-4 lg:p-6 border-2 rounded-lg cursor-pointer ${boxGradients[index % boxGradients.length]} ${
//             boxNumber === userBox 
//               ? 'border-yellow-400 shadow-lg shadow-yellow-400/50 scale-105 z-10' 
//               : 'border-transparent hover:scale-102'
//           } transition-all duration-300 aspect-[3/2]`}
//         >
//           <span className={`text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-center mb-1 sm:mb-2 ${
//             boxNumber === userBox ? 'text-yellow-400' : 'text-white'
//           }`}>
//             Box {boxNumber}
//           </span>
//           <span className={`text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-center ${
//             boxNumber === userBox ? 'text-yellow-400' : 'text-white'
//           }`}>
//             {amount}
//           </span>
//           {boxNumber === userBox && (
//             <>
//               <div className="absolute inset-0 border-4 border-yellow-400 rounded-lg animate-pulse"></div>
//               <div className="absolute inset-0 bg-yellow-400 opacity-0 rounded-lg animate-glow"></div>
//             </>
//           )}
//         </div>
//       ))}
//       <style jsx>{`
//         @keyframes glow {
//           0%, 100% { opacity: 0; }
//           50% { opacity: 0.3; }
//         }
//         .animate-glow {
//           animation: glow 2s infinite;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default RewardsGrid;



///###########################################################   no animation

// import React from 'react';

// const RewardsGrid = ({ rewards, userBox }) => {
//   const boxGradients = [
//     'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
//     'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
//     'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
//     'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
//     'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
//     'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
//   ];

//   const parsedRewards = rewards.map((reward) => {
//     const [boxString, amount] = reward.split(" - ");
//     const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//     return { boxNumber, amount };
//   });

//   return (
//     <div className="grid grid-cols-2 gap-2 sm:gap-3 md:gap-4 my-5 w-full">
//       {parsedRewards.map(({ boxNumber, amount }, index) => (
//         <div
//           key={index}
//           className={`relative flex flex-col justify-center items-center p-2 sm:p-3 md:p-4 lg:p-6 border-2 rounded-lg cursor-pointer ${boxGradients[index % boxGradients.length]} ${
//             boxNumber === userBox 
//               ? 'border-yellow-400 shadow-lg shadow-yellow-400/50 scale-105 z-10' 
//               : 'border-transparent hover:scale-102'
//           } transition-all duration-300 aspect-[3/2]`}
//         >
//           <span className={`text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-center mb-1 sm:mb-2 ${
//             boxNumber === userBox ? 'text-yellow-400' : 'text-white'
//           }`}>
//             Box {boxNumber}
//           </span>
//           <span className={`text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-center ${
//             boxNumber === userBox ? 'text-yellow-400' : 'text-white'
//           }`}>
//             {amount}
//           </span>
//           {boxNumber === userBox && (
//             <div className="absolute inset-0 border-4 border-yellow-400 rounded-lg animate-pulse"></div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RewardsGrid;

// import React from 'react';

// const RewardsGrid = ({ rewards, userBox }) => {
//   const boxGradients = [
//     'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
//     'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
//     'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
//     'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
//     'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
//     'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
//   ];

//   const parsedRewards = rewards.map((reward) => {
//     const [boxString, amount] = reward.split(" - ");
//     const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//     return { boxNumber, amount };
//   });

//   return (
//     <div className="grid grid-cols-2 gap-2 sm:gap-3 md:gap-4 my-5 w-full">
//       {parsedRewards.map(({ boxNumber, amount }, index) => (
//         <div
//           key={index}
//           className={`flex flex-col justify-center items-center p-2 sm:p-3 md:p-4 lg:p-6 border border-gray-300 rounded-lg cursor-pointer ${boxGradients[index % boxGradients.length]} ${
//             boxNumber === userBox ? 'shadow-lg shadow-black/50 ring-2 ring-white' : ''
//           } transition-all duration-300 hover:scale-102 aspect-[3/2]`}
//         >
//           <span className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-white font-bold text-center mb-1 sm:mb-2">
//             Box {boxNumber}
//           </span>
//           <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-white text-center">
//             {amount}
//           </span>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RewardsGrid;

// import React from 'react';

// const RewardsGrid = ({ rewards, userBox }) => {
//   const boxGradients = [
//     'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
//     'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
//     'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
//     'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
//     'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
//     'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
//   ];

//   const parsedRewards = rewards.map((reward) => {
//     const [boxString, amount] = reward.split(" - ");
//     const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//     return { boxNumber, amount };
//   });

//   return (
//     <div className="grid grid-cols-2 gap-3 sm:gap-4 my-5 mx-auto w-full">
//       {parsedRewards.map(({ boxNumber, amount }, index) => (
//         <div
//           key={index}
//           className={`flex flex-col justify-center items-center p-3 sm:p-4 md:p-5 border border-gray-300 rounded-lg cursor-pointer ${boxGradients[index % boxGradients.length]} ${
//             boxNumber === userBox ? 'shadow-lg shadow-black/50 ring-2 ring-white' : ''
//           } transition-all duration-300 hover:scale-105`}
//         >
//           <span className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-white font-bold text-center mb-1 sm:mb-2">
//             Box {boxNumber}
//           </span>
//           <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white text-center">
//             {amount}
//           </span>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RewardsGrid;

// import React from 'react';

// const RewardsGrid = ({ rewards, userBox }) => {
//   const boxGradients = [
//     'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
//     'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
//     'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
//     'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
//     'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
//     'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
//   ];

//   const parsedRewards = rewards.map((reward) => {
//     const [boxString, amount] = reward.split(" - ");
//     const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//     return { boxNumber, amount };
//   });

//   return (
//     <div className="grid grid-cols-2 gap-3 sm:gap-4 my-5 mx-auto max-w-7xl px-2 sm:px-4">
//       {parsedRewards.map(({ boxNumber, amount }, index) => (
//         <div
//           key={index}
//           className={`flex justify-center items-center h-32 border border-gray-300 rounded-lg cursor-pointer ${boxGradients[index % boxGradients.length]} ${
//             boxNumber === userBox ? 'shadow-lg shadow-black/50' : ''
//           }`}
//         >
//           <span className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-white font-bold text-center px-2 sm:px-3">
//             Box {boxNumber}:<br />{amount}
//           </span>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RewardsGrid;



// import React from 'react';

// const RewardsGrid = ({ rewards, userBox }) => {
//   const boxGradients = [
//     'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
//     'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
//     'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
//     'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
//     'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
//     'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
//   ];

//   const parsedRewards = rewards.map((reward) => {
//     const [boxString, amount] = reward.split(" - ");
//     const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//     return { boxNumber, amount };
//   });

//   return (
//     <div className="grid grid-cols-2 gap-3 sm:gap-4 my-5 mx-auto max-w-6xl px-2 sm:px-4">
//       {parsedRewards.map(({ boxNumber, amount }, index) => (
//         <div
//           key={index}
//           className={`flex justify-center items-center h-28 sm:h-36 md:h-44 lg:h-52 xl:h-60 border border-gray-300 rounded-lg cursor-pointer ${boxGradients[index % boxGradients.length]} ${
//             boxNumber === userBox ? 'shadow-lg shadow-black/50' : ''
//           }`}
//         >
//           <span className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-white font-bold text-center px-2 sm:px-3">
//             Box {boxNumber}:<br />{amount}
//           </span>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RewardsGrid;

// const RewardsGrid = ({ rewards, userBox }) => {
//   const boxGradients = [
//     'bg-gradient-to-br from-[#73BF1E] to-[#36590E]', // green gradient
//     'bg-gradient-to-br from-[#C3391E] to-[#5D1B0E]', // orange gradient
//     'bg-gradient-to-br from-[#04296F] to-[#084FD4]', // blue gradient
//     'bg-gradient-to-br from-[#4E0092] to-[#17002C]', // purple gradient
//     'bg-gradient-to-br from-[#E14400] to-[#7B2500]', // bright orange gradient
//     'bg-gradient-to-br from-[#A70000] to-[#410000]', // red gradient
//   ];

//   const parsedRewards = rewards.map((reward) => {
//     const [boxString, amount] = reward.split(" - ");
//     const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//     return { boxNumber, amount };
//   });

//   return (
//     <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 my-5">
//       {parsedRewards.map(({ boxNumber, amount }, index) => (
//         <div
//           key={index}
//           className={`flex justify-center items-center h-24 border border-gray-300 rounded-md cursor-pointer ${boxGradients[index % boxGradients.length]} ${
//             boxNumber === userBox ? 'shadow-lg shadow-black/50' : ''
//           }`}
//         >
//           <span className="text-xl text-white font-bold">
//             Box {boxNumber}: {amount}
//           </span>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RewardsGrid;

// // Add this component inside your PaymentForm component
// const RewardsGrid = ({ rewards, userBox }) => {
//     //const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"];

//     const boxColors = [
//       'linear-gradient(to bottom right, #73BF1E, #36590E)', // green gradient
//       'linear-gradient(to bottom right, #C3391E, #5D1B0E)', // orange gradient
//       'linear-gradient(to bottom right, #04296F, #084FD4)', // blue gradient
//       'linear-gradient(to bottom right, #4E0092, #17002C)', // purple gradient
//       'linear-gradient(to bottom right, #E14400, #7B2500)', // bright green gradient
//       'linear-gradient(to bottom right, #A70000, #410000)', // red gradient
//     ];
//     const gridStyles = {
//       display: "grid",
//       gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//       gap: "10px",
//       marginTop: "20px",
//       marginBottom: "20px",
//     };
//     const boxStyle = {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       height: "100px",
//       border: "1px solid #ccc",
//       borderRadius: "5px",
//       cursor: "pointer",
//     };
//     const boxNumberStyle = {
//       fontSize: "1.5em",
//       color: "white",
//       fontWeight: "bold",
//     };
  
//     const parsedRewards = rewards.map((reward) => {
//       const [boxString, amount] = reward.split(" - ");
//       const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
//       return { boxNumber, amount };
//     });


  
//     return (
//       <div style={gridStyles}>
//         {parsedRewards.map(({ boxNumber, amount }, index) => (
//           <div
//             key={index}
//             className="box"
//             style={{
//               ...boxStyle,
//               backgroundColor: boxColors[index % boxColors.length],
//               boxShadow: boxNumber === userBox ? "0px 0px 5px 3px #000" : "none",
//             }}
//           >
//             <span style={boxNumberStyle}>
//               Box {boxNumber}: {amount}
//             </span>
//           </div>
//         ))}
//       </div>
//     );
//   };
  

//   export default RewardsGrid