import React from "react";
import Footer from "../components/ghana/footer";
import Nav from "../components/ghana/Nav";




const renderSubsections = (subsections) => {
  return subsections.map((subsection, subIndex) => {
    if (typeof subsection === "string") {
      return (
        <li
          key={subIndex}
          className="text-gray-300">
          {subsection}
        </li>
      );
    } else if (typeof subsection === "object") {
      return (
        <li
          key={subIndex}
          className="text-gray-300">
          <h3 className="font-semibold text-gray-100">{subsection.title}</h3>
          <ul className="list-disc pl-5 space-y-2">
            {renderSubsections(subsection.subsections)}
          </ul>
        </li>
      );
    }
  });
};
function TermsAndConditions() {

  const sections = [
    {
      title: "1. Introduction",
      subsections: [
        "1.1 The MegaCash Lucky Box Game is conducted in a fair and transparent manner.",
        "1.2 By entering the MegaCash Lucky Box Game (\"MegaCash Lucky Box\" or the \"Game\"), participants agree to be bound by the following terms and conditions.",
        "1.3 These terms and conditions (the \"Terms\") apply to participation in the MegaCash Lucky Box Game."
      ]
    },
    {
      title: "2. Eligibility",
      subsections: [
        "2.1 Participants must be 18 years of age or older in accordance with the laws of Ghana.",
        "2.2 By entering the Game, a participant confirms their eligibility to play and to claim any prize that may be won.",
        "2.3 Registered mobile numbers used for the Game should be registered for mobile money on the respective mobile network.",
        "2.4 Participants will be identified by their registered mobile numbers used to enter the Game.",
        "2.5 Employees, affiliates, and immediate family members of the organizers of MegaCash Lucky Box are ineligible to participate in the Game.",
        "2.6 Participation in the game is restricted to individuals within Ghana; those located outside the country are not eligible to play."
      ]
    },
    {
      title: "3. Entries and Entry Fee's",
      subsections: [
        "3.1 Participants may enter the Game multiple times.",
        "3.2 Multiple entries by a participant do not guarantee a reward but may increase a participant's chances of winning.",
        "3.3 Entry fee to the Game is NON-REFUNDABLE.",
        "3.4 The minimum entry fee to the Game is GHS5, and the maximum entry fee is GHS2,000."
      ]
    },
    {
      title: "4. How to enter the Game",
      subsections: [
        "4.1 A participant enters the Game by dialing USSD short code advertised by a Station or Media House or via web portal.",
        "4.2 The Short code is accessible on all mobile networks in Ghana (i.e. MTN, Vodafone, AT-Airtel Tigo).",
        "4.3 Participants would have to follow the prompts provided on the short code or web interface until they complete the process. They will receive confirmation via SMS once their entry is successfully submitted.",
        "4.4 The Game generates automatic winners by immediately informing participants whether they have won or not after a session has been completed.",
        "4.5 MegaCash Lucky Box will not be responsible for any issues arising from mobile network failures or unreliable connectivity while participants play the Game.",
        "4.6 MegaCash Lucky Box reserves the right to modify, amend, suspend, postpone or cancel the Game in whole or in part, at its sole discretion where it becomes necessary to do so.",
        "4.7 Winner Verification",
        {
          title: "4.7.1 Eligibility Verification",
          subsections: [
            "a) All winners must meet the eligibility criteria as specified in these Terms.",
            "b) MegaCash Lucky Box reserves the right to verify the eligibility of a winner, which may include but not limited to the age and identity of a participant.",
            "c) Winners may be required to provide proof of their age and identity upon request. Failure to provide such proof may result in the disqualification of a participant."
          ]
        },
        {
          title: "4.7.2 Rightful Ownership of Mobile number",
          subsections: [
            "a) Winners must be the rightful owner of the registered mobile number used to enter the Game. Using another person's mobile number without proper authorization may lead to disqualification."
          ]
        }
      ]
    },
    {
      title: "5. The Prizes",
      subsections: [
        "5.1 Prizes will be automatically transferred onto the winner's Mobile Money Account upon completion of the USSD or web process.",
        "5.2 Cash prizes for the Game will be duly displayed on the Game's website and may be amended from time to time.",
        "5.3 The prizes offered are NON-EXCHANGEABLE and NON-TRANSFERABLE.",
        "5.4 MegaCash Lucky Box reserves the right to substitute a participant's prize with another prize of equal or higher value if circumstances beyond its control make it necessary to do so.",
        "5.5 Cash Prizes",
        {
          title: "a) Cash prizes will be delivered to the winners via Mobile Money transfer, in accordance with the payment details provided by the winners.",
          subsections: [
            "b) Winners are not responsible for any delivery charges associated with receiving their cash prizes."
          ]
        },
        "5.6 Delivery Timeframes/Acceptance",
        {
          title: "5.6.1 MegaCash Lucky Box will make reasonable efforts to deliver cash prizes promptly. However, where there is a delay, due to factors beyond the Games's control, MegaCash Lucky Box and its media partners will not be liable for any delays or issues that occur during the delivery process.",
          subsections: [
            "5.6.2 Winners are responsible for accepting their prize upon delivery. If a prize cannot be delivered successfully due to the winner's unavailability or refusal to accept it, MegaCash Lucky Box reserves the right to deem the prize forfeited."
          ]
        }
      ]
    },
    {
      title: "6. Data Protection and Publicity",
      subsections: [
        "6.1 Data Collection and Use",
        {
          title: "a) MegaCash Lucky Box will collect and process participant data solely for the purpose of administering the Game.",
          subsections: [
            "b) Personal data collected may include, but is not limited to, names, contact information, and any other information necessary for the administration of the Game.",
            "c) By participating in the Game, participants consent to the collection, processing, and use of their data for this purpose."
          ]
        },
        "6.2 Data Security",
        "MegaCash Lucky Box will implement appropriate technical and organizational measures to protect participant data from unauthorized access, loss, alteration, or disclosure, in compliance with the Data Protection Act, 2012 (Act 843) of Ghana and other relevant regulations.",
        "6.3 Data Retention",
        "A participant's data will only be retained for as long as necessary to fulfill the Game.",
        "6.4 Data Sharing",
        "MegaCash Lucky Box will not sell, share, or disclose a participant's data to third parties, except as required by law or where necessary for the administration of the Game.",
        "6.5 Winners agree to the release of portions of their mobile numbers used to enter the Game for the purpose of announcing winners and promoting the MegaCash Lucky Box Game across radio, TV, social media, and newspapers.",
        "6.6 Participants may apply for information regarding their play by contacting sparkhousepromotions@gmail.com.",
        "6.7 A participant's data will be collected and processed in accordance with the Data Protection Act, 2012 (Act 843) of Ghana."
      ]
    },
    {
      title: "7. Limitation of liability",
      subsections: [
        "7.1 Exclusion of Liability",
        "MegaCash Lucky Box, its Partners, Officers, Employees, Agents, and Affiliates shall not be liable for any loss, damage, or injury arising from or in connection with the Game, including but not limited to technical problems, unauthorized access, errors, interruptions, bodily injury, property damage, or personal harm.",
        "7.2 Third-Party Services",
        "MegaCash Lucky Box is not responsible for any liabilities, disputes, or issues arising from the use of third-party services, such as payment processors.",
        "7.3 Participant Responsibility",
        {
          title: "a) Participants are responsible for ensuring that their actions comply with the Game's rules as well as relevant Ghanaian laws and regulations.",
          subsections: [
            "b) MegaCash Lucky Box will not be liable for any failure by participants to adhere to the Terms and any applicable law and/or regulation."
          ]
        },
        "7.4 Notification and Communication",
        "MegaCash Lucky Box will not be liable for any loss or harm arising from failed or delayed communication, including email or SMS notifications, or any inaccurate contact information provided by participants.",
        "7.5 Misconduct and Fraud",
        {
          title: "a) MegaCash Lucky Box will not be liable for any misconduct or fraudulent activities by participants, including but not limited to attempts to manipulate or cheat in the Game.",
          subsections: [
            "b) Social media platforms used by MegaCash Lucky Box, or its partners are not responsible for the Game, and any issues related to these platforms are not the Game's liability."
          ]
        }
      ]
    },
    {
      title: "8. Force Majeure",
      subsections: [
        "8.1 Event Beyond Control",
        "MegaCash Lucky Box WILL NOT BE LIABLE for any failure or delay in the performance of its obligations under these Terms caused by events beyond its reasonable control, including but not limited to acts of God, war, terrorism, natural disasters, strikes, government actions, and other events commonly referred to as \"Force Majeure Events\".",
        "8.2 Notice and Mitigation",
        {
          title: "a) In the event of a Force Majeure Event, MegaCash Lucky Box will use its best efforts to mitigate the effects of the Event and continue the Game to the extent reasonably possible.",
          subsections: [
            "b) MegaCash Lucky Box shall promptly notify participants of the occurrence of a Force Majeure Event and its expected impact on the Game through its media partners."
          ]
        },
        "8.3 Termination or Modification",
        "If a Force Majeure Event continues for an extended period, MegaCash Lucky Box reserves the right to, at its sole discretion, either:",
        {
          title: "a) Temporarily suspend the Game until the Force Majeure Event is resolved; or",
          subsections: [
            "b) Modify the Game, its duration, or its terms to account for the Force Majeure Event."
          ]
        }
      ]
    },
    {
      title: "9. Accessibility Considerations",
      subsections: [
        "9.1 Inclusivity",
        "Participants with disability who may require some accommodation to fully engage in the Game may contact MegaCash Lucky Box at sparkhousepromotions@gmail.com to discuss their specific needs.",
        "9.2 Feedback and Concerns",
        "MegaCash Lucky Box welcomes feedback from participants with disabilities on how we can improve accessibility in the Game. If you have any concerns or recommendations related to accessibility, please contact us at sparkhousepromotions@gmail.com. MegaCash Lucky Box is committed to addressing and resolving accessibility issues promptly.",
        "9.3 Compliance",
        "MegaCash Lucky Box is committed to complying with all applicable laws and regulations related to accessibility for individuals with disabilities, including the Persons with Disabilities Act, 2006 (Act 715) of Ghana."
      ]
    },
    {
      title: "10. Disqualification",
      subsections: [
        "10.1 Grounds for Disqualification",
        "MegaCash Lucky Box reserves the right to disqualify participants who engage in any of the following forms of misconduct:",
        {
          title: "a) Fraud: any attempt to manipulate, deceive, or engage in fraudulent activity to gain an unfair advantage in the Game, including Misrepresentation.",
          subsections: [
            "b) Cheating, Tampering/Collusion: any behaviour that breaches the rules and fairness of the Game, such as collusion with other participants to increase chances of winning.",
            "c) Inappropriate Conduct: any action that is disrespectful, offensive, or harmful to other participants or MegaCash Lucky Box or its Partners, whether in communications, online interactions, or any other aspect of the Game.",
            "d) Unsubstantiated Claims: participants making false or unverified claims about the Game, the prizes, or promotional benefits.",
            "e) Violation of the Terms: Failure to comply with any of the terms and conditions outlined in this document, including age and eligibility requirements."
          ]
        },
        "10.2 Disqualification Process",
        "If MegaCash Lucky Box suspects or identifies any misconduct by a participant, it may investigate the matter, considering any evidence and feedback. If, after such investigation, misconduct of the participant is confirmed, MegaCash Lucky Box reserves the right to disqualify the participant in question.",
        "10.3 Effects of Disqualification",
        "In cases of disqualification due to misconduct, the disqualified participant will forfeit any rights to the Game, including any prizes, without any liability on the part of the MegaCash Lucky Box."
      ]
    },
    {
      title: "11. Governing Law, Regulation & Customer Support",
      subsections: [
        "11.1 All draws in the Game are subject to regulation by National Lottery Authority and will be governed by the laws of Ghana.",
        "11.2 MegaCash Lucky Box reserves the right to periodically update these Terms. The latest version of the Terms will be available on the MegaCash Lucky Box website, and any updates will become effective immediately upon being published on the site.",
        "11.3 Customer support is available to assist with any difficulties. You can reach us by email at sparkhousepromotions@gmail.com for support, complaints, or disputes."
      ]
    },
    {
      title: "12. Dispute Resolution",
      subsections: [
        "a) In the event of any dispute, controversy, or claim arising out of or relating to the Game, participants agree to first attempt to resolve the matter through internal procedures established by the organizers of MegaCash Lucky Box, including contacting the organizer's customer support or designated representative.",
        "b) If a dispute cannot be resolved through the internal procedures, the parties agree that the Ghanaian courts shall have exclusive jurisdiction to hear and determine any disputes, legal actions, or proceedings arising out of or in connection with the Game, and all parties submit to the jurisdiction of the Ghanaian courts."
      ]
    },
    {
      title: "13. General",
      subsections: [
        "a) These Terms constitute the entire agreement between a participant and MegaCash Lucky Box regarding the Game.",
        "b) All participants agree to be bound by the most recent version of these Terms.",
        "c) By participating in the Game, participants acknowledge that they have read, understood, and agreed to these Terms, including the dispute resolution process outlined herein."
      ]
    }
  ];

return(

    <>
      

      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 text-white bg-[#061126] mt-8">
      <div className="max-w-7xl mx-auto">
        <h1
          className="text-4xl font-bold text-white text-center bg-cover bg-center py-7"
          style={{
            backgroundImage: "url('terms.svg')",
          }}>
          Terms and Conditions
        </h1>

        <div className="flex flex-wrap justify-center pt-4 gap-8 mb-10">
          {sections.map((section, index) => (
            <div
              key={index}
              className="w-full md:w-5/12 bg-[#061126] border-2 border-[#05358F] rounded-lg p-6 shadow-lg mb-8">
              <h2 className="text-2xl font-semibold text-yellow-400 mb-4">
                {section.title}
              </h2>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                {section.subsections.map((subsection, subIndex) => (
                  <li key={subIndex}>
                    {typeof subsection === "string" ? (
                      subsection
                    ) : (
                      <div>
                        <h3 className=" text-gray-300 mt-2">
                          {subsection.title}
                        </h3>
                        <ul className="list-disc list-inside pl-4 space-y-1">
                          {subsection.subsections.map((item, itemIndex) => (
                            <li
                              key={itemIndex}
                              className="text-gray-300">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
}

export default TermsAndConditions;

// const renderSubsections = (subsections) => {
//   return subsections.map((subsection, subIndex) => {
//     if (typeof subsection === "string") {
//       return (
//         <li
//           key={subIndex}
//           className="text-gray-300">
//           {subsection}
//         </li>
//       );
//     } else if (typeof subsection === "object") {
//       return (
//         <li
//           key={subIndex}
//           className="text-gray-300">
//           <h3 className="font-semibold text-yellow-500">{subsection.title}</h3>
//           <ul className="list-disc pl-5 space-y-2">
//             {renderSubsections(subsection.subsections)}
//           </ul>
//         </li>
//       );
//     }
//   });
// };

// function TermsAndConditions() {

//   const sections = [
//     {
//       title: "1. Introduction",
//       subsections: [
//         "1.1 The MegaCash Lucky Box Game is conducted in a fair and transparent manner.",
//         '1.2 By entering the MegaCash Lucky Box Game (“MegaCash Lucky Box” or the “Game”), participants agree to be bound by the following terms and conditions.',
//         "1.3 These terms and conditions (the “Terms”) apply to participation in the MegaCash Lucky Box Game.",
//       ],
//     },
//     {
//       title: "2. Eligibility",
//       subsections: [
//         "2.1 Participants must be 18 years of age or older in accordance with the laws of Ghana.",
//         "2.2 By entering the Game, a participant confirms their eligibility to play and to claim any prize that may be won.",
//         "2.3 Registered mobile numbers used for the Game should be registered for mobile money on the respective mobile network.",
//         "2.4 Participants will be identified by their registered mobile numbers used to enter the Game.",
//         "2.5 Employees, affiliates, and immediate family members of the organizers of MegaCash Lucky Box are ineligible to participate in the Game.",
//         "2.6 Participation in the game is restricted to individuals within Ghana; those located outside the country are not eligible to play.",
//       ],
//     },
//     {
//       title: "3. Entries and Entry Fee’s",
//       subsections: [
//         "3.1 Participants may enter the Game multiple times.",
//         "3.2 Multiple entries by a participant do not guarantee a reward but may increase a participant’s chances of winning.",
//         "3.3 Entry fee to the Game is NON-REFUNDABLE.",
//         "3.4 The minimum entry fee to the Game is GHS5, and the maximum entry fee is GHS2,000.",
//       ],
//     },
//     {
//       title: "4. How to enter the Game",
//       subsections: [
//         "4.1 A participant enters the Game by dialing USSD short code advertised by a Station or Media House or via web portal.",
//         "4.2 The Short code is accessible on all mobile networks in Ghana (i.e. MTN, Vodafone, AT-Airtel Tigo).",
//         "4.3 Participants would have to follow the prompts provided on the short code or web interface until they complete the process. They will receive confirmation via SMS once their entry is successfully submitted.",
//         "4.4 The Game generates automatic winners by immediately informing participants whether they have won or not after a session has been completed.",
//         "4.5 MegaCash Lucky Box will not be responsible for any issues arising from mobile network failures or unreliable connectivity while participants play the Game.",
//         "4.6 MegaCash Lucky Box reserves the right to modify, amend, suspend, postpone or cancel the Game in whole or in part, at its sole discretion where it becomes necessary to do so.",
//         "4.7 All winners must meet the eligibility criteria as specified in these Terms.",
//         "4.8 MegaCash Lucky Box reserves the right to verify the eligibility of a winner, which may include but not limited to the age and identity of a participant.",
//         "4.9 Winners may be required to provide proof of their age and identity upon request. Failure to provide such proof may result in the disqualification of a participant.",
//         "4.10 Winners must be the rightful owner of the registered mobile number used to enter the Game. Using another person’s mobile number without proper authorization may lead to disqualification.",
//       ],
//     },
//     {
//       title: "5. The Prizes",
//       subsections: [
//         "5.1 Prizes will be automatically transferred onto the winner’s Mobile Money Account upon completion of the USSD or web process.",
//         "5.2 Cash prizes for the Game will be duly displayed on the Game’s website and may be amended from time to time.",
//         "5.3 The prizes offered are NON-EXCHANGEABLE and NON-TRANSFERABLE.",
//         "5.4 MegaCash Lucky Box reserves the right to substitute a participant’s prize with another prize of equal or higher value if circumstances beyond its control make it necessary to do so.",
//       ],
//     },
//     {
//       title: "6. Cash Prizes",
//       subsections: [
//         "6.1 Cash prizes will be delivered to the winners via Mobile Money transfer, in accordance with the payment details provided by the winners.",
//         "6.2 Winners are not responsible for any delivery charges associated with receiving their cash prizes.",
//         "6.3 MegaCash Lucky Box will make reasonable efforts to deliver cash prizes promptly. However, where there is a delay, due to factors beyond the Games's control, MegaCash Lucky Box and its media partners will not be liable for any delays or issues that occur during the delivery process.",
//         "6.4 Winners are responsible for accepting their prize upon delivery. If a prize cannot be delivered successfully due to the winner's unavailability or refusal to accept it, MegaCash Lucky Box reserves the right to deem the prize forfeited.",
//       ],
//     },
//     {
//       title: "7. Data Protection and Publicity",
//       subsections: [
//         "7.1 MegaCash Lucky Box will collect and process participant data solely for the purpose of administering the Game.",
//         "7.2 Personal data collected may include, but is not limited to, names, contact information, and any other information necessary for the administration of the Game.",
//         "7.3 By participating in the Game, participants consent to the collection, processing, and use of their data for this purpose.",
//         "7.4 MegaCash Lucky Box will implement appropriate technical and organizational measures to protect participant data from unauthorized access, loss, alteration, or disclosure, in compliance with the Data Protection Act, 2012 (Act 843) of Ghana and other relevant regulations.",
//         "7.5 A participant’s data will only be retained for as long as necessary to fulfill the Game.",
//         "7.6 MegaCash Lucky Box will not sell, share, or disclose a participant’s data to third parties, except as required by law or where necessary for the administration of the Game.",
//         "7.7 Winners agree to the release of portions of their mobile numbers used to enter the Game for the purpose of announcing winners and promoting the MegaCash Lucky Box Game across radio, TV, social media, and newspapers.",
//         "7.8 Participants may apply for information regarding their play by contacting sparkhousepromotions@gmail.com.",
//         "7.9 A participant’s data will be collected and processed in accordance with the Data Protection Act, 2012 (Act 843) of Ghana.",
//       ],
//     },
//     {
//       title: "8. Limitation of Liability",
//       subsections: [
//         "8.1 MegaCash Lucky Box, its Partners, Officers, Employees, Agents, and Affiliates shall not be liable for any loss, damage, or injury arising from or in connection with the Game, including but not limited to technical problems, unauthorized access, errors, interruptions, bodily injury, property damage, or personal harm.",
//         "8.2 MegaCash Lucky Box is not responsible for any liabilities, disputes, or issues arising from the use of third-party services, such as payment processors.",
//         "8.3 Participants are responsible for ensuring that their actions comply with the Game's rules as well as relevant Ghanaian laws and regulations.",
//         "8.4 MegaCash Lucky Box will not be liable for any failure by participants to adhere to the Terms and any applicable law and/or regulation.",
//         "8.5 MegaCash Lucky Box will not be liable for any loss or harm arising from failed or delayed communication, including email or SMS notifications, or any inaccurate contact information provided by participants.",
//         "8.6 MegaCash Lucky Box will not be liable for any misconduct or fraudulent activities by participants, including but not limited to attempts to manipulate or cheat in the Game.",
//         "8.7 Social media platforms used by MegaCash Lucky Box, or its partners are not responsible for the Game, and any issues related to these platforms are not the Game's liability.",
//       ],
//     },
//     {
//       title: "9. Force Majeure",
//       subsections: [
//         "9.1 MegaCash Lucky Box will not be liable for any failure or delay in the performance of its obligations under these Terms caused by events beyond its reasonable control, including but not limited to acts of God, war, terrorism, natural disasters, strikes, government actions, and other events commonly referred to as “Force Majeure Events”.",
//         "9.2 In the event of a Force Majeure Event, MegaCash Lucky Box will use its best efforts to mitigate the effects of the Event and continue the Game to the extent reasonably possible.",
//         "9.3 MegaCash Lucky Box shall promptly notify participants of the occurrence of a Force Majeure Event and its expected impact on the Game through its media partners.",
//         "9.4 If a Force Majeure Event continues for an extended period, MegaCash Lucky Box reserves the right to, at its sole discretion, either temporarily suspend the Game until the Force Majeure Event is resolved, or modify the Game, its duration, or its terms to account for the Force Majeure Event.",
//       ],
//     },
//     {
//       title: "10. Accessibility Considerations",
//       subsections: [
//         "10.1 Participants with disabilities who may require some accommodation to fully engage in the Game may contact MegaCash Lucky Box at sparkhousepromotions@gmail.com to discuss their specific needs.",
//         "10.2 MegaCash Lucky Box welcomes feedback from participants with disabilities on how we can improve accessibility in the Game. If you have any concerns or recommendations related to accessibility, please contact us at sparkhousepromotions@gmail.com.",
//         "10.3 Compliance  <br> MegaCash Lucky Box is committed to complying with all applicable laws and regulations related to accessibility for individuals with disabilities, including the Persons with Disabilities Act, 2006 (Act 715) of Ghana.",
//       ],
//     },
  
//     {
//       title: "11. Disqualification",
//       subsections: [
//         "11.1 Grounds for Disqualification",
//         "MegaCash Lucky Box reserves the right to disqualify participants who engage in any of the following forms of misconduct:",
//         "a) Fraud: any attempt to manipulate, deceive, or engage in fraudulent activity to gain an unfair advantage in the Game, including Misrepresentation.",
//         "b) Cheating, Tampering/Collusion: any behaviour that breaches the rules and fairness of the Game, such as collusion with other participants to increase chances of winning.",
//         "c) Inappropriate Conduct: any action that is disrespectful, offensive, or harmful to other participants or MegaCash Lucky Box or its Partners, whether in communications, online interactions, or any other aspect of the Game.",
//         "d) Unsubstantiated Claims: participants making false or unverified claims about the Game, the prizes, or promotional benefits.",
//         "e) Violation of the Terms: Failure to comply with any of the terms and conditions outlined in this document, including age and eligibility requirements.",
//         "11.2 Disqualification Process",
//         "If MegaCash Lucky Box suspects or identifies any misconduct by a participant, it may investigate the matter, considering any evidence and feedback. If, after such investigation, misconduct of the participant is confirmed, MegaCash Lucky Box reserves the right to disqualify the participant in question.",
//         "11.3 Effects of Disqualification",
//         "In cases of disqualification due to misconduct, the disqualified participant will forfeit any rights to the Game, including any prizes, without any liability on the part of the MegaCash Lucky Box.",
//       ],
//     },
//     {
//       title: "12. Governing Law, Regulation & Customer Support",
//       subsections: [
//         "12.1 All draws in the Game are subject to regulation by National Lottery Authority and will be governed by the laws of Ghana.",
//         "12.2 MegaCash Lucky Box reserves the right to periodically update these Terms. The latest version of the Terms will be available on the MegaCash Lucky Box website, and any updates will become effective immediately upon being published on the site.",
//         "12.3 Customer support is available to assist with any difficulties. You can reach us by email at sparkhousepromotions@gmail.com for support, complaints, or disputes.",
//       ],
//     },
//     {
//       title: "13. Dispute Resolution",
//       subsections: [
//         "13.1 In the event of any dispute, controversy, or claim arising out of or relating to the Game, participants agree to first attempt to resolve the matter through internal procedures established by the organizers of MegaCash Lucky Box, including contacting the organizer's customer support or designated representative.",
//         "13.2 If a dispute cannot be resolved through the internal procedures, the parties agree that the Ghanaian courts shall have exclusive jurisdiction to hear and determine any disputes, legal actions, or proceedings arising out of or in connection with the Game, and all parties submit to the jurisdiction of the Ghanaian courts.",
//       ],
//     },
//     {
//       title: "14. General",
//       subsections: [
//         "14.1 These Terms constitute the entire agreement between a participant and MegaCash Lucky Box regarding the Game.",
//         "14.2 All participants agree to be bound by the most recent version of these Terms.",
//         "14.3 By participating in the Game, participants acknowledge that they have read, understood, and agreed to these Terms, including the dispute resolution process outlined herein.",
//       ],
//     },
//   ];


// return(

//     <>
//       <Nav />

//       <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 text-white bg-[#061126] mt-8 ">
//         <div className="max-w-7xl mx-auto">
//           <h1
//             className="text-4xl font-bold text-white text-center bg-cover bg-center  py-7 "
//             style={{
//               backgroundImage: "url('terms.svg')",
//             }}>
//             Terms and Conditions
//           </h1>

//           {/* Introduction */}
          

//           <div className="flex flex-wrap justify-center pt-4 gap-8 mb-10">
//             {sections.map((section, index) => (
//               <div
//                 key={index}
//                 className="w-full md:w-5/12 bg-[#061126] border-2 border-[#05358F] rounded-lg p-6 shadow-lg mb-8">
//                 <h2 className="text-2xl font-semibold text-yellow-400 mb-4">
//                   {section.title}
//                 </h2>
//                 <ul className="list-disc list-inside text-gray-200 space-y-2">
//                   {section.subsections.map((subsection, subIndex) => (
//                     <li key={subIndex}>
//                       {typeof subsection === "string" ? (
//                         subsection
//                       ) : (
//                         <div>
//                           <h3 className="font-semibold text-yellow-500 mt-2">
//                             {subsection.title}
//                           </h3>
//                           <ul className="list-disc list-inside pl-4 space-y-1">
//                             {subsection.subsections.map((item, itemIndex) => (
//                               <li
//                                 key={itemIndex}
//                                 className="text-gray-300">
//                                 {item}
//                               </li>
//                             ))}
//                           </ul>
//                         </div>
//                       )}
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default TermsAndConditions;