import React, { useState } from "react";

function FAQSection() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What is MegaCash Lucky Box?",
      answer:
        "MegaCash Lucky Box is an exciting game of chance where players select boxes in hopes of winning instant rewards. Each box holds potential prizes, and you have the option to play via USSD (*426*6#) or our website www.megacashluckybox.com. Choose a box and see if luck is on your side!",
    },
    {
      question: "How do I play MegaCash Lucky Box?",
      answer:
        "To play, simply select a box from BOX 1 to BOX 6. If you’re lucky, you’ll win a prize! You can participate by dialing *426*6# or by visiting our website. The more you play, the more chances you have to win exciting rewards!",
    },
    {
      question: "My OTP or game result was delayed. What should I do?",
      answer:
        "We’ve resolved recent delays in OTP and game result delivery. You can now check your OTP and game results by dialing *426*10#. If you are still having issues, please contact our support team.",
    },
    {
      question: "How are winnings credited?",
      answer:
        "Winnings are directly credited to your Mobile Money account, and you’ll receive an SMS notification once it’s processed. If you haven’t received the notification, dial *426*10# and select option 2 to check your results.",
    },
    {
      question: "Can I get a refund?",
      answer:
        "As a chance-based game, refunds are generally unavailable once transactions are completed. If a technical issue impacts your game, please provide your transaction details, and we’ll investigate.",
    },
    {
      question: "I have a payment or transaction inquiry.",
      answer:
        "If you have questions about a payment, please provide your transaction ID and phone number. Our team will verify the payment status and promptly address any discrepancies.",
    },
    {
      question: "What if I experience a technical issue while playing?",
      answer:
        "If you encounter any issues while playing, share the details with our support team via \n📱 WhatsApp: +233(0)531100702\n📞 Phone: +233(0)303980440\n📧 Email: info@megacashluckybox.com\nPlease have your phone number and transaction ID ready. We’ll investigate and work to resolve it as soon as possible.",
    },
    {
      question: "Does MegaCash Lucky Box have an app?",
      answer:
        "Currently, we do not have an app. However, you can still enjoy the game on our website or through USSD. We’re working on an app for future releases, so stay tuned!",
    },
    {
      question: "How does the game of chance work?",
      answer:
        "MegaCash Lucky Box is a game of chance, meaning outcomes are entirely based on luck. Selecting a box doesn’t guarantee a win but gives you a fair chance at exciting rewards.",
    },
    {
      question: "What are game points, and how do they work?",
      answer:
        "Points accumulated through gameplay increase your chances of winning bigger rewards. Every money spent contributes to your overall experience and adds value. Keep playing to boost your chances!",
    },
    {
      question: "What amount and prizes can I win in Mega Cash Lucky Box?",
      answer:
        "Prizes range from small cash amounts to the grand jackpot, which can reach millions. The exact prize structure is available on our official website or game tickets.",
    },
    {
      question: "Can I play Mega Cash Lucky Box more than once?",
      answer:
        "Yes, you can play as many times as you like. Each ticket represents a separate entry into the draw.",
    },
    {
      question: "How are the winners notified?",
      answer:
        "Winners are notified via sms . Major winners may be contacted directly.",
    },
    {
      question: "How can I contact MegaCash Lucky Box support?",
      answer:
        "If you need assistance or have questions, please reach out to our support team:\n📱 WhatsApp: +233(0)531100702\n📞 Phone: +233(0)303980440\n📧 Email: info@megacashluckybox.com\nOur team is here to help and will respond as quickly as possible!",
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <div className="mt-24">
        <h2 className="text-2xl font-bold mb-6 text-center text-white">
          Frequently asked questions (FAQs)
        </h2>
      </div>
      <div className="text-white p-4 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col md:flex-row items-start justify-between">
            <div className="w-full md:w-2/3 pr-0 md:pr-8 order-2 md:order-1">
              <div className="space-y-4">
                {faqs.map((faq, index) => (
                  <div
                    key={index}
                    className="border-b border-gray-700 pb-2"
                  >
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => toggleFAQ(index)}
                    >
                      <p className="text-sm">{`${index + 1}. ${faq.question}`}</p>
                      <button className="text-yellow-400 font-bold text-xl">
                        {openIndex === index ? "-" : "+"}
                      </button>
                    </div>
                    {openIndex === index && (
                      <p className="text-sm mt-2 text-gray-400 whitespace-pre-line">
                        {faq.answer}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full md:w-1/3 mb-8 md:mb-0 order-1 md:order-2 flex justify-center md:justify-end">
              <div className="rounded-lg p-2 relative overflow-hidden sm:max-w-96 h-2/3">
                <img
                  src="question.svg"
                  alt="FAQ illustration"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQSection;


// import React, { useState } from "react";


// function FAQSection() {
//   const [openIndex, setOpenIndex] = useState(null);

//   const faqs = [
//     {
//       question: "What is Mega Cash Lucky Box?",
//       answer:
//         "Mega Cash Lucky Box is an exciting lottery-style game where players have the chance to win substantial cash prizes by selecting lucky numbers .",
//     },
//     {
//       question: "How do I play Mega Cash Lucky Box?",
//       answer:
//         "To play, purchase a ticket online or from an authorized retailer. Choose your lucky numbers or opt for a quick pick. Match the drawn numbers to win prizes.",
//     },
//     {
//       question: "What amount and prizes can I win in Mega Cash Lucky Box?",
//       answer:
//         "Prizes range from small cash amounts to the grand jackpot, which can reach millions. The exact prize structure is available on our official website or game tickets.",
//     },
//     {
//       question: "How do I participate in the game?",
//       answer:
//         "You can participate by purchasing a ticket through our website, mobile app, or from authorized retailers. Follow the instructions to select your numbers and complete the purchase.",
//     },
//     {
//       question: "Can I play Mega Cash Lucky Box more than once?",
//       answer:
//         "Yes, you can play as many times as you like. Each ticket represents a separate entry into the draw.",
//     },
//     {
//       question: "How are the winners notified?",
//       answer:
//         "Winners are notified via email for online purchases. For physical tickets, check the results on our website, app, or at the point of purchase. Major winners may be contacted directly.",
//     },
//     {
//       question: "Is Mega Cash Lucky Box a fair game?",
//       answer:
//         "Absolutely. We use a certified random number generator for our draws, which is regularly audited by independent third parties to ensure fairness and randomness.",
//     },
//     {
//       question: "Where can I find more information about Mega Cash Lucky Box?",
//       answer:
//         "Detailed information is available on our official website, including game rules, odds of winning, and prize breakdowns. You can also contact our customer support for specific queries.",
//     },
//     {
//       question: "How is the Mega Cash Lucky Box game regulated?",
//       answer:
//         "Mega Cash Lucky Box is regulated by [insert relevant gaming authority]. We adhere to strict guidelines to ensure fair play and responsible gaming practices.",
//     },
//     {
//       question: "What should I do if I encounter issues with the game?",
//       answer:
//         "If you experience any issues, please contact our customer support team immediately. They're equipped to handle technical problems, account issues, and general inquiries.",
//     },
//     {
//       question: "How can I contact customer support for Mega Cash Lucky Box?",
//       answer:
//         "You can reach our customer support team via email at support@megacashluckybox.com, by phone at 1-800-XXX-XXXX, or through the live chat feature on our website. Support is available 24/7.",
//     },
//   ];

//   const toggleFAQ = (index) => {
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   return (
//     <div>
     
//       <div className="mt-24">
//         <h2 className="text-2xl font-bold mb-6 text-center text-white">
//           Frequently asked questions (FAQs)
//         </h2>
//       </div>
//       <div className="text-white p-4 md:p-8">
//         <div className="max-w-6xl mx-auto">
//           <div className="flex flex-col md:flex-row items-start justify-between">
//             <div className="w-full md:w-2/3 pr-0 md:pr-8 order-2 md:order-1">
//               <div className="space-y-4">
//                 {faqs.map((faq, index) => (
//                   <div
//                     key={index}
//                     className="border-b border-gray-700 pb-2">
//                     <div
//                       className="flex items-center justify-between cursor-pointer"
//                       onClick={() => toggleFAQ(index)}>
//                       <p className="text-sm">{`${index + 1}. ${faq.question}`}</p>
//                       <button className="text-yellow-400 font-bold text-xl">
//                         {openIndex === index ? "-" : "+"}
//                       </button>
//                     </div>
//                     {openIndex === index && (
//                       <p className="text-sm mt-2 text-gray-400">{faq.answer}</p>
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </div>
//             <div className="w-full md:w-1/3 mb-8 md:mb-0 order-1 md:order-2 flex justify-center md:justify-end">
//               <div className="rounded-lg p-2 relative overflow-hidden sm:max-w-96 h-2/3">
//                 <img
//                   src="question.svg"
//                   alt="FAQ illustration"
//                   className="w-full h-full object-cover rounded-lg"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
 
//     </div>
//   );
// }

// export default FAQSection;