// import { useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";

// export default function Footer() {
//   const footerStyles = "p-6 bg-[#EFD05C] text-center";
//   const location = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to top on every route change
//   }, [location]);

//   return (
//     <>
//       <div className={footerStyles}>
//         <h2 className="font-bold text-2xl my-4">Who Are We?</h2>
//         <p className="px-6 md:px-40 lg:px-64 text-gray-800">
//           Mega Cash Box is an exciting and interactive game that offers players
//           a thrilling experience of chance and reward. In this captivating game,
//           users are presented with six boxes, each potentially containing cash or surprises.
//         </p>

//         <div className="my-6">
//           <h2 className="font-bold text-2xl my-4">Our Partner</h2>
//           <div className="flex justify-center my-4">
//             <img src="caritas.svg" alt="caritas" className="mx-2 w-[82px]" />
//           </div>
//         </div>

//         <div className="flex flex-col md:flex-row justify-center gap-6 mb-6">
//           <Link className="underline text-[#061126] hover:text-[#ECA34D] transition-colors" to="/termsConditions">
//             Terms and Conditions
//           </Link>
//           <Link className="underline text-[#061126] hover:text-[#ECA34D] transition-colors" to="/privacypolicy">
//             Privacy Policy
//           </Link>
//         </div>
//       </div>

//       <div className="bg-white p-6 text-center font-bold">
//         <div className="flex flex-wrap justify-center items-center gap-6 mb-4">
//           <p className="flex items-center text-gray-700">
//             <span className="mr-2">Talk to us:</span>
//             <a href="tel:+233303980440" className="text-[#061126] hover:text-[#ECA34D] transition-colors">
//               +233(0)303980440
//             </a>
//           </p>
//           <p className="flex items-center text-gray-700">
//             <span className="mr-2">Email us at:</span>
//             <a 
//               className="underline text-[#061126] hover:text-[#ECA34D] transition-colors" 
//               href="mailto:contact@megacashluckybox.com"
//             >
//               contact@megacashluckybox.com
//             </a>
//           </p>
//         </div>

//         <p className="text-gray-700">
//           &copy; {new Date().getFullYear()} Copyright:
//           <Link
//             to="/"
//             className="underline text-[#061126] hover:text-[#ECA34D] transition-colors ml-1"
//           >
//             MegaCashBox
//           </Link>
//         </p>
//       </div>
//     </>
//   );
// }




// import { useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";

// export default function Footer() {
//   const footerStyles = " p-2 bg-[#EFD05C] text-center ";
//   const location = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to top on every route change
//   }, [location]);

//   return (
//     <>
//       <div className={footerStyles}>
        
//         <h2 className="font-bold text-xl my-2">Who are we?</h2>
//         <p className=" px-0 md:px-80">
//           Mega Cash Box is an exciting and interactive game that offers players
//           a thrilling experience of chance and reward.In this captivating game,
//           users are presented with six boxes, each potentially containing cash
//           or suprises.{" "}
//         </p>
//         <div className=" ">
//           <h2 className="font-bold text-xl my-2">Our partner</h2>
//           <div className="flex justify-center my-2">
           
//             <img src="caritas.svg" alt="caritas" className="mx-2 w-[82px]" />
//           </div>
//         </div>
//         <p className="mx-6 md:inline-block">
//           <Link
//             className="underline text-[#061126]"
//             to="/termsConditions">
//             Terms and Conditions
//           </Link>
//         </p>
//         <Link
//           className="underline text-[#061126]"
//           to="/privacypolicy">
//           Privacy Policy
//         </Link>
//       </div>
//       <div className="bg-white p-4 text-center font-bold">
//         <div className="flex flex-wrap justify-center items-center gap-4 mb-2">
//           <p className="flex items-center">
//             <span className="mr-2">Talk to us</span>
//             <a href="tel:+233303980440" className="text-[#061126] hover:text-[#EFD05C] transition-colors">
//               +233(0)303980440
//             </a>
//           </p>
//           <p className="flex items-center">
//             <span className="mr-2">Email us at</span>
//             <a 
//               className="underline text-[#061126] hover:text-[#EFD05C] transition-colors" 
//               href="mailto:contact@megacashluckybox.com"
//             >
//               contact@megacashluckybox.com
//             </a>
//           </p>
//         </div>

//         <p>
//           &copy; {new Date().getFullYear()} Copyright:
//           <Link
//             to="/"
//             className="underline text-[#061126] hover:text-[#EFD05C] transition-colors ml-1">
//             MegaCashBox
//           </Link>
//         </p>
//       </div>
//     </>
//   );
// }

// import { useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";

// export default function Footer() {
//   const footerStyles = " p-2 bg-[#EFD05C] text-center ";
//   const location = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to top on every route change
//   }, [location]);

//   return (
//     <>
//       <div className={footerStyles}>
        
//         <h2 className="font-bold text-xl my-2">Who are we?</h2>
//         <p className=" px-0 md:px-80">
//           Mega Cash Box is an exciting and interactive game that offers players
//           a thrilling experience of chance and reward.In this captivating game,
//           users are presented with six boxes, each potentially containing cash
//           or suprises.{" "}
//         </p>
//         <div className=" ">
//           <h2 className="font-bold text-xl my-2">Our partner</h2>
//           <div className="flex justify-center my-2">
           
//             <img src="caritas.svg" alt="caritas" className="mx-2 w-[82px]" />
//           </div>
//         </div>
//         <p className="mx-6 md:inline-block">
//           <Link
//             className="underline text-[#061126]"
//             to="/termsConditions">
//             Terms and Conditions
//           </Link>
//         </p>
//         <Link
//           className="underline text-[#061126]"
//           to="/privacypolicy">
//           Privacy Policy
//         </Link>
//       </div>
//       <div className="bg-white p-4 text-center font-bold">
//         <div className="flex flex-wrap justify-center items-center gap-4 mb-2">
//           <p className="flex items-center">
//             <span className="mr-2">Talk to us</span>
//             <a href="tel:+233303980440" className="text-[#061126] hover:text-[#EFD05C] transition-colors">
//               +233(0)303980440
//             </a>
//           </p>
//           <p className="flex items-center">
//             <span className="mr-2">Email us at</span>
//             <a 
//               className="underline text-[#061126] hover:text-[#EFD05C] transition-colors" 
//               href="mailto:contact@megacashluckybox.com"
//             >
//               contact@megacashluckybox.com
//             </a>
//           </p>
//         </div>

//         <p>
//           &copy; {new Date().getFullYear()} Copyright:
//           <Link
//             to="/"
//             className="underline text-[#061126] hover:text-[#EFD05C] transition-colors ml-1">
//             MegaCashBox
//           </Link>
//         </p>
//       </div>
//     </>
//   );
// }












import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
  const footerStyles = " p-2 bg-[#EFD05C] text-center ";
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on every route change
  }, [location]);

  return (
    <>
      <div className={footerStyles}>
        
        <h2 className="font-bold text-xl my-2">Who are we?</h2>
        <p className=" px-0 md:px-80">
          Mega Cash Box is an exciting and interactive game that offers players
          a thrilling experience of chance and reward.In this captivating game,
          users are presented with six boxes, each potentially containing cash
          or suprises.{" "}
        </p>
        <div className=" ">
          <h2 className="font-bold text-xl my-2">Our partner</h2>
          <div className="flex justify-center my-2">
           
            <img src="caritas.svg" alt="caritas" className="mx-2 w-[82px]" />
          </div>
        </div>
        <p className="mx-6 md:inline-block">
          <Link
            className="underline text-[#061126]"
            to="/termsConditions">
            Terms and Conditions
          </Link>
        </p>
        <Link
          className="underline text-[#061126]"
          to="/privacypolicy">
          Privacy Policy
        </Link>
      </div>
      <div className="bg-white p-4 text-center font-bold ">
      <p className="py-2">
  WhatsApp +233(0)531100702 
</p>
<p className="py-2">
  Talk to us   +233(0)303980440 
</p>
<p className="pb-2"> Email us at <a className="underline" href="https://mail.google.com/mail/?view=cm&fs=1&to=info@megacashluckybox.com">info@megacashluckybox.com</a></p>


        &copy; {new Date().getFullYear()} Copyright:
        <Link
          to="/"
          className="underline text-[#061126]">
          MegaCashBox
        </Link>


      </div>
    </>
  );
}