// Helper component for rendering operator logos and names
/*const Operator = ({ logo, name, onClick, selected }) => (
    <div style={{
      margin:'10px',
      background:'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      padding: '8px',
      cursor: 'pointer',
      border: selected ? '2px solid #ee6525' : 'none',  // Use the new highlight color
      borderRadius: '10px',  // Rounded corners for the highlight box
      transition: 'border 0.3s'  // Smooth transition for the border color change
    }} onClick={onClick}>
      <img src={logo} alt={name} style={{ width: '100px', height: '50px', objectFit: 'contain' }} />
      <span style={{ textAlign: 'center', marginTop: '10px' }}>{name}</span>
    </div>
  );*/
  const Operator = ({ logo, name, onClick, selected }) => (
    <button 
      onClick={onClick}
      className={`
        mx-1 bg-white rounded-lg p-3 flex flex-col flex-shrink-0  items-center justify-center
        cursor-pointer transition-all duration-300  md:w-16 md:h-16 w-16 h-16
        ${selected ? 'ring-[3px] ring-orange-500' : ''}
      `}
    >
      <img 
        src={logo} 
        alt={name} 
        className="w-full h-full object-contain"
      />
       {/* <span className=" text-black md:text-md text-xs font-semibold">{name}</span> */}
    </button>
  );




  export default Operator;