

function Steps() {
  const steps = [
    {
      title: "Enter your Mobile Number",
      description:
        "Put the address you would like to use in the designated field. Make sure it's correctly formatted.",
    },
    {
      title: "Enter the Amount",
      description:
        "Input the amount you wish to play with. For example, you can enter 25 GHS.",
    },
    {
      title: "Choose Your Mobile Money Operator",
      description:
        "Select your preferred mobile money operator from the available options.",
    },
    {
      title: "Choose your Lucky Box",
      description:
        "Pick one of the six available boxes. Each box contains a different prize.",
    },
    {
      title: "Initiate Payment",
      description:
        "Click the 'PLAY' button to start the payment process. An STK push will be sent to your phone.",
    },
    {
      title: "Confirm Payment",
      description:
        "On your mobile device, enter your mobile money PIN to confirm the payment.",
    },
    {
      title: "View your Prize",
      description:
        "After payment, you'll see a confirmation screen showing the prize you've won.",
    },
    {
      title: "Play Again (Optional)",
      description:
        "If you wish to play again, click the 'Play Again' button and repeat the steps.",
    },
    {
      title: "Thank you..",
      description: "",
    },
  ];

  return (
    <div>
      
      <div>
        <div className="pt-20">
          <div className="text-white p-8">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-3xl font-bold text-center mb-10">
                How to play the game ?
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 lg:mx-40">
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`aspect-square w-full max-w-[240px] mx-auto ${
                      index === steps.length - 1 && steps.length % 3 !== 0
                        ? "md:col-start-2 md:col-span-1"
                        : ""
                    }`}>
                    <div className="bg-[#EFD05C] rounded-lg p-4 text-black flex flex-col items-center justify-center text-center h-full border-2 border-white">
                      {index < 8 && (
                        <span className="text-xl font-bold mb-2">
                          {index + 1}
                        </span>
                      )}
                      <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                      <p className="overflow-hidden">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
}

export default Steps;