import { Home01Icon, TiktokIcon, InstagramIcon,  Facebook01Icon, TwitterIcon, NewTwitterRectangleIcon} from "hugeicons-react";


const Socials = () => {
    return (
      <ul className="flex flex-row justify-center items-center md:z-auto z-[-1] md:p-2 p-0  md:my-4 md:mr-16 mr-[250px] ml-6 md:ml-52  md:bg-[#0d1941] md:static rounded-md space-x-2 max-w-[180px] ">
        
        <li>
          <a
            href="https://www.tiktok.com/@mega.cash.lucky.box"  target="blank"
            className="text-white hover:text-yellow-600 transition duration-300">
           < TiktokIcon size={28}  color="#fffff"/>
          </a>
        </li>
  
        <li>
          <a
            href="https://www.instagram.com/megacashluckybox/" target="blank"
            className="text-white hover:text-yellow-600 transition duration-300">
            <InstagramIcon size={28}  color="#fffff"/>
          </a>
        </li>
  
        <li>
          <a
            href="https://www.facebook.com/people/Mega-Cash-Lucky-Box/61563858452307/" target="blank"
            className="text-white hover:text-yellow-600   transition duration-300">
            <Facebook01Icon size={28}  color="#fffff"/>
          </a>
        </li>
        <li>
          <a
            href="https://x.com/Megacashghana" target="blank"
            className="text-white hover:text-yellow-600   transition duration-300">
            <NewTwitterRectangleIcon size={28}  color="#fffff"/>
          </a>
        </li>
      </ul>
    );
  };
  
  export default Socials;