import React from "react";


function LoadingSpinner() {
  const spinnerColors = [
    "bg-blue-500",    // primary
    "bg-gray-500",    // secondary
    "bg-green-500",   // success
    "bg-red-500",     // danger
    "bg-yellow-500",  // warning
    "bg-cyan-500",    // info
    "bg-blue-500",    // primary (repeated)
    "bg-gray-800",    // dark
  ];

  return (
    <div className="flex space-x-2">
      {spinnerColors.map((color, index) => (
        <div key={index} className="relative">
          <div
            className={`w-6 h-6 ${color} mx-1 rounded-full animate-ping`}
            role="status"
          ></div>
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      ))}
    </div>
  );
}

export default LoadingSpinner;


// import React from "react";


// function LoadingSpinner() {
//   const spinnerColors = [
//     "bg-blue-500",    // primary
//     "bg-gray-500",    // secondary
//     "bg-green-500",   // success
//     "bg-red-500",     // danger
//     "bg-yellow-500",  // warning
//     "bg-cyan-500",    // info
//     "bg-blue-500",    // primary (repeated)
//     "bg-gray-800",    // dark
//   ];

//   return (
//     <div className="flex space-x-2">
//       {spinnerColors.map((color, index) => (
//         <div key={index} className="relative">
//           <div
//             className={`w-6 h-6 ${color} rounded-full animate-ping`}
//             role="status"
//           ></div>
//           <span className="sr-only">Loading...</span>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default LoadingSpinner;
// function LoadingSpinner() {
//   return (
//     <div className="flex justify-center items-center min-h-screen">
//       <div className="flex space-x-6">
//         <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="w-10 h-10 border-4 border-gray-500 border-t-transparent rounded-full animate-spin" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="w-10 h-10 border-4 border-green-500 border-t-transparent rounded-full animate-spin" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="w-10 h-10 border-4 border-red-500 border-t-transparent rounded-full animate-spin" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="w-10 h-10 border-4 border-yellow-500 border-t-transparent rounded-full animate-spin" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="w-10 h-10 border-4 border-teal-500 border-t-transparent rounded-full animate-spin" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="w-10 h-10 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//         <div className="w-10 h-10 border-4 border-black border-t-transparent rounded-full animate-spin" role="status">
//           <span className="sr-only">Loading...</span>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default LoadingSpinner;

