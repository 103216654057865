import React, { useState, useEffect } from "react";

const images = [
  { src: "banner1.jpg" },
  { src: "BANNER2.jpg" },
  { src: "banner3.jpg" },
];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel-container flex justify-center mt-5 md:p-10 border-2 md:ml-10 rounded-xl md:mx-14 border-[#0446a948]">
      <img
        src={images[currentIndex].src}
        alt={`Carousel ${currentIndex}`}
        className="carousel-image"
        style={{
          objectFit: "contain", // Ensures the image is visible
        }}
      />
    </div>
  );
};

export default ImageCarousel;




// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// const images = [
//   { src: "banner1.jpg" },
//   { src: "BANNER2.jpg" },
//   { src: "banner3.jpg" },
// ];

// const ImageCarousel = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 5000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="flex justify-center mt-5 md:p-10 border-2 md:ml-10 rounded-xl md:mx-14 border-[#0446a948]">
//       <AnimatePresence mode="wait">
//         <motion.img
//           key={currentIndex}
//           src={images[currentIndex].src}
//           alt={`Carousel${currentIndex}`}
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//           transition={{ duration: 0.5 }}
//         />
//       </AnimatePresence>
//     </div>
//   );
// };

// export default ImageCarousel;




// import React, { useState, useEffect } from "react";

// const images = [
//   { src: "banner1.jpg" },
//   { src: "BANNER2.jpg" },
//   { src: "banner3.jpg" },
// ];

// const ImageCarousel = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="flex justify-center mt-5 md:p-10 border-2  md:ml-10 rounded-xl md:mx-14 border-[#0446a948]">
//       <img
//         src={images[currentIndex].src}
//         alt={`Carousel${currentIndex}`}
//       />
//     </div>
//   );
// };

// export default ImageCarousel;


// import React from "react";

// function ImageDisplay  () {
//   return (
//     <div className="flex justify-center mt-5 md:p-10 border-2  md:ml-10 rounded-xl md:mx-14 border-[#0446a948]">
//       <img src="banner1.jpg" className="rounded-xl" alt="Advertisement" />
//     </div>
//   );
// };

// export default ImageDisplay;