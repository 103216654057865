import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Utility functions (unchanged)

// Updated function to format date based on African countries
const formatDate = (dateString, country = 'GH') => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  // Most African countries use DD/MM/YYYY format
  const defaultFormat = `${day}/${month}/${year}`;

  // Object to store exceptions or specific formats
  const countryFormats = {
    'KE': `${day}/${month}/${year}`, // Kenya
    'ZA': `${year}/${month}/${day}`, // South Africa
    'ET': `${day}.${month}.${year}`, // Ethiopia
    'EG': `${day}-${month}-${year}`, // Egypt
    'NG': `${day}/${month}/${year}`, // Nigeria
    'GH': `${day}/${month}/${year}`, // Ghana (default)
    // Add more countries here if they have specific formats
  };

  return countryFormats[country] || defaultFormat;
};


// Generate a random phone number based on the first digits
const generateRandomPhoneNumber = (firstDigits) => {
  const firstDigit =
    firstDigits[Math.floor(Math.random() * firstDigits.length)];
  const remainingDigits = Array.from({ length: 6 }, () =>
    Math.floor(Math.random() * 10)
  );
  return `${firstDigit}${remainingDigits.join("")}`;
};

// Generate a random bet amount without rounding to multiples of 5
const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
  const range = maxBetAmount - minBetAmount + 1;
  const betAmount = Math.floor(Math.random() * range) + minBetAmount;
  return betAmount;
};

// Generate fake game data ensuring winningAmount < 50
const generateFakeData = (
  count,
  countryCode,
  firstDigits,
  minBetAmount,
  maxBetAmount
) => {
  return Array.from({ length: count }, (_, i) => {
    const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);

    // Calculate the maximum possible factor to keep winningAmount below 50
    const maxFactor = Math.floor((50 - 1) / betAmount); // Subtract 1 to ensure it's below 50

    // Ensure that maxFactor is at least 1
    const adjustedMaxFactor = Math.max(maxFactor, 1);

    // Generate a random factor between 1 and adjustedMaxFactor
    const randomFactor = Math.floor(Math.random() * adjustedMaxFactor) + 1;

    const winningAmount = betAmount * randomFactor;

    return {
      _id: `fake_id_${i}_${Date.now()}`,
      date: new Date().toISOString(),
      betAmount,
      winningAmount,
      phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
      status: "Win",
    };
  }).sort((a, b) => b.winningAmount - a.winningAmount);
};

function DisplayTable({
  countryCode,
  currency,
  firstDigits,
  minBetAmount,
  maxBetAmount,
  count,
  tableHeading,
  dateFormat = 'GH' // Default to Ghana
}) {
  const [games, setGames] = useState([]);

  useEffect(() => {
    const updateData = () => {
      const generatedGames = generateFakeData(
        count,
        countryCode,
        firstDigits,
        minBetAmount,
        maxBetAmount
      );

      if (window.innerWidth < 768) {
        setGames(generatedGames.slice(0, 10));
      } else {
        setGames(generatedGames);
      }
    };

    updateData();
    const interval = setInterval(updateData, 10000);
    return () => clearInterval(interval);
  }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

  return (
    <div className="col-12 col-md-7 pt-5 mb-5">
      <div className="promo pe-md-3 pe-lg-5">
        <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
          {tableHeading}
        </h1>
        <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
          <table className="table w-full text-white md:text-sm text-xs">
            <thead>
              <tr className="bg-[#061126] border-b-2">
                <th className="px-4 py-3 text-left">Date</th>
                <th className="px-4 py-3 text-left">Win</th>
                <th className="px-4 py-3 text-left">Phone</th>
                <th className="px-4 py-3 text-left">Status</th>
              </tr>
            </thead>
            <AnimatePresence initial={false}>
              <tbody>
                {games.length === 0 ? (
                  <tr>
                    <td className="px-4 py-3 text-center" colSpan={4}>
                      No Record Found
                    </td>
                  </tr>
                ) : (
                  games.map((game, index) => (
                    <motion.tr
                      key={game._id}
                      initial={{ opacity: 0, y: -20, scale: 0.9 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{ opacity: 0, y: 20, scale: 0.9 }}
                      transition={{
                        duration: 0.8,
                        delay: index * 0.05,
                        ease: "easeInOut",
                      }}
                      className={`${
                        index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
                      }`}
                    >
                      <td>{formatDate(game.date, dateFormat)}</td>
                      <td>
                        <motion.span
                          initial={{
                            scale: 1,
                            textShadow: "0 0 0px rgba(255,255,0,0)",
                          }}
                          animate={{
                            scale: [1, 1.05, 1],
                            textShadow: [
                              "0 0 0px rgba(255,255,0,0)",
                              "0 0 10px rgba(255,255,0,0.8)",
                              "0 0 0px rgba(255,255,0,0)",
                            ],
                          }}
                          transition={{
                            duration: 2,
                            repeat: Infinity,
                            repeatType: "reverse",
                          }}
                          className="font-bold text-yellow-400 inline-block"
                        >
                          {currency} {game.winningAmount.toLocaleString()}
                        </motion.span>
                      </td>
                      <td>{`${game.phoneNumber.slice(
                        0,
                        6
                      )}xxxx${game.phoneNumber.slice(-1)}`}</td>
                      <td
                        className={`px-4 py-3 ${game.status.toLowerCase()} ${
                          index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
                        }`}
                      >
                        {game.status}
                      </td>
                    </motion.tr>
                  ))
                )}
              </tbody>
            </AnimatePresence>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DisplayTable;


// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// // Utility functions (unchanged)


// // Generate a random phone number based on the first digits
// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// // Generate a random bet amount without rounding to multiples of 5
// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return betAmount;
// };

// // Generate fake game data ensuring winningAmount < 50
// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);

//     // Calculate the maximum possible factor to keep winningAmount below 50
//     const maxFactor = Math.floor((50 - 1) / betAmount); // Subtract 1 to ensure it's below 50

//     // Ensure that maxFactor is at least 1
//     const adjustedMaxFactor = Math.max(maxFactor, 1);

//     // Generate a random factor between 1 and adjustedMaxFactor
//     const randomFactor = Math.floor(Math.random() * adjustedMaxFactor) + 1;

//     const winningAmount = betAmount * randomFactor;

//     return {
//       _id: `fake_id_${i}_${Date.now()}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };



// // New function to format date in Ghana format (DD/MM/YYYY)
// const formatDateGhana = (dateString) => {
//   const date = new Date(dateString);
//   const day = date.getDate().toString().padStart(2, '0');
//   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
//   const year = date.getFullYear();
//   return `${day}/${month}/${year}`;
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       if (window.innerWidth < 768) {
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     updateData();
//     const interval = setInterval(updateData, 10000);
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <AnimatePresence initial={false}>
//               <tbody>
//                 {games.length === 0 ? (
//                   <tr>
//                     <td className="px-4 py-3 text-center" colSpan={4}>
//                       No Record Found
//                     </td>
//                   </tr>
//                 ) : (
//                   games.map((game, index) => (
//                     <motion.tr
//                       key={game._id}
//                       initial={{ opacity: 0, y: -20, scale: 0.9 }}
//                       animate={{ opacity: 1, y: 0, scale: 1 }}
//                       exit={{ opacity: 0, y: 20, scale: 0.9 }}
//                       transition={{
//                         duration: 0.8,
//                         delay: index * 0.05,
//                         ease: "easeInOut",
//                       }}
//                       className={`${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       <td>{formatDateGhana(game.date)}</td>
//                       <td>
//                         <motion.span
//                           initial={{
//                             scale: 1,
//                             textShadow: "0 0 0px rgba(255,255,0,0)",
//                           }}
//                           animate={{
//                             scale: [1, 1.05, 1],
//                             textShadow: [
//                               "0 0 0px rgba(255,255,0,0)",
//                               "0 0 10px rgba(255,255,0,0.8)",
//                               "0 0 0px rgba(255,255,0,0)",
//                             ],
//                           }}
//                           transition={{
//                             duration: 2,
//                             repeat: Infinity,
//                             repeatType: "reverse",
//                           }}
//                           className="font-bold text-yellow-400 inline-block"
//                         >
//                           {currency} {game.winningAmount.toLocaleString()}
//                         </motion.span>
//                       </td>
//                       <td>{`${game.phoneNumber.slice(
//                         0,
//                         6
//                       )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                       <td
//                         className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                           index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                         }`}
//                       >
//                         {game.status}
//                       </td>
//                     </motion.tr>
//                   ))
//                 )}
//               </tbody>
//             </AnimatePresence>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;

// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// // Utility functions

// // Generate a random phone number based on the first digits
// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// // Generate a random bet amount without rounding to multiples of 5
// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return betAmount;
// };

// // Generate fake game data ensuring winningAmount < 50
// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);

//     // Calculate the maximum possible factor to keep winningAmount below 50
//     const maxFactor = Math.floor((50 - 1) / betAmount); // Subtract 1 to ensure it's below 50

//     // Ensure that maxFactor is at least 1
//     const adjustedMaxFactor = Math.max(maxFactor, 1);

//     // Generate a random factor between 1 and adjustedMaxFactor
//     const randomFactor = Math.floor(Math.random() * adjustedMaxFactor) + 1;

//     const winningAmount = betAmount * randomFactor;

//     return {
//       _id: `fake_id_${i}_${Date.now()}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       if (window.innerWidth < 768) {
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     updateData();
//     const interval = setInterval(updateData, 10000);
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <AnimatePresence initial={false}>
//               <tbody>
//                 {games.length === 0 ? (
//                   <tr>
//                     <td className="px-4 py-3 text-center" colSpan={4}>
//                       No Record Found
//                     </td>
//                   </tr>
//                 ) : (
//                   games.map((game, index) => (
//                     <motion.tr
//                       key={game._id}
//                       initial={{ opacity: 0, y: -20, scale: 0.9 }}
//                       animate={{ opacity: 1, y: 0, scale: 1 }}
//                       exit={{ opacity: 0, y: 20, scale: 0.9 }}
//                       transition={{
//                         duration: 0.8, // Slower animation
//                         delay: index * 0.05, // Slight delay for stagger effect
//                         ease: "easeInOut",
//                       }}
//                       className={`${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       <td>{new Date(game.date).toLocaleString()}</td>
//                       <td>
//                         <motion.span
//                           initial={{
//                             scale: 1,
//                             textShadow: "0 0 0px rgba(255,255,0,0)",
//                           }}
//                           animate={{
//                             scale: [1, 1.05, 1],
//                             textShadow: [
//                               "0 0 0px rgba(255,255,0,0)",
//                               "0 0 10px rgba(255,255,0,0.8)",
//                               "0 0 0px rgba(255,255,0,0)",
//                             ],
//                           }}
//                           transition={{
//                             duration: 2,
//                             repeat: Infinity,
//                             repeatType: "reverse",
//                           }}
//                           className="font-bold text-yellow-400 inline-block"
//                         >
//                           {currency} {game.winningAmount.toLocaleString()}
//                         </motion.span>
//                       </td>
//                       <td>{`${game.phoneNumber.slice(
//                         0,
//                         6
//                       )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                       <td
//                         className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                           index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                         }`}
//                       >
//                         {game.status}
//                       </td>
//                     </motion.tr>
//                   ))
//                 )}
//               </tbody>
//             </AnimatePresence>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;



// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// // ... (keep the existing utility functions: generateRandomPhoneNumber, generateRandomBetAmount, generateFakeData)

// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}_${Date.now()}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       if (window.innerWidth < 768) {
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     updateData();
//     const interval = setInterval(updateData, 10000);
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <AnimatePresence initial={false}>
//               <tbody>
//                 {games.length === 0 ? (
//                   <tr>
//                     <td className="px-4 py-3 text-center" colSpan={4}>
//                       No Record Found
//                     </td>
//                   </tr>
//                 ) : (
//                   games.map((game, index) => (
//                     <motion.tr
//                       key={game._id}
//                       initial={{ opacity: 0, y: -20, scale: 0.9 }}
//                       animate={{ opacity: 1, y: 0, scale: 1 }}
//                       exit={{ opacity: 0, y: 20, scale: 0.9 }}
//                       transition={{
//                         duration: 0.8, // Slower animation
//                         delay: index * 0.2, // Increase delay for stagger effect
//                         ease: "easeInOut",
//                       }}
//                       className={`${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       <td>{new Date(game.date).toDateString()}</td>
//                       <td>
//                         <motion.span
//                           initial={{
//                             scale: 1,
//                             textShadow: "0 0 0px rgba(255,255,0,0)",
//                           }}
//                           animate={{
//                             scale: [1, 1.05, 1],
//                             textShadow: [
//                               "0 0 0px rgba(255,255,0,0)",
//                               "0 0 10px rgba(255,255,0,0.8)",
//                               "0 0 0px rgba(255,255,0,0)",
//                             ],
//                           }}
//                           transition={{
//                             duration: 2,
//                             repeat: Infinity,
//                             repeatType: "reverse",
//                           }}
//                           className="font-bold text-yellow-400 inline-block"
//                         >
//                           {currency} {game.winningAmount.toLocaleString()}
//                         </motion.span>
//                       </td>
//                       <td>{`${game.phoneNumber.slice(
//                         0,
//                         6
//                       )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                       <td
//                         className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                           index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                         }`}
//                       >
//                         {game.status}
//                       </td>
//                     </motion.tr>
//                   ))
//                 )}
//               </tbody>
//             </AnimatePresence>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;

// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// // ... (keep the existing utility functions: generateRandomPhoneNumber, generateRandomBetAmount, generateFakeData)

// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}_${Date.now()}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       if (window.innerWidth < 768) {
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     updateData();
//     const interval = setInterval(updateData, 5000);
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <AnimatePresence initial={false}>
//               <tbody>
//                 {games.length === 0 ? (
//                   <tr>
//                     <td className="px-4 py-3 text-center" colSpan={4}>
//                       No Record Found
//                     </td>
//                   </tr>
//                 ) : (
//                   games.map((game, index) => (
//                     <motion.tr
//                       key={game._id}
//                       initial={{ opacity: 0, y: -20, scale: 0.9 }}
//                       animate={{ opacity: 1, y: 0, scale: 1 }}
//                       exit={{ opacity: 0, y: 20, scale: 0.9 }}
//                       transition={{
//                         duration: 0.4,
//                         delay: index * 0.1,
//                         ease: "easeInOut",
//                       }}
//                       className={`${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       <td>{new Date(game.date).toDateString()}</td>
//                       <td>
//                         <motion.span
//                           initial={{
//                             scale: 1,
//                             textShadow: "0 0 0px rgba(255,255,0,0)",
//                           }}
//                           animate={{
//                             scale: [1, 1.05, 1],
//                             textShadow: [
//                               "0 0 0px rgba(255,255,0,0)",
//                               "0 0 10px rgba(255,255,0,0.8)",
//                               "0 0 0px rgba(255,255,0,0)",
//                             ],
//                           }}
//                           transition={{
//                             duration: 2,
//                             repeat: Infinity,
//                             repeatType: "reverse",
//                           }}
//                           className="font-bold text-yellow-400 inline-block"
//                         >
//                           {currency} {game.winningAmount.toLocaleString()}
//                         </motion.span>
//                       </td>
//                       <td>{`${game.phoneNumber.slice(
//                         0,
//                         6
//                       )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                       <td
//                         className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                           index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                         }`}
//                       >
//                         {game.status}
//                       </td>
//                     </motion.tr>
//                   ))
//                 )}
//               </tbody>
//             </AnimatePresence>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;


// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// // ... (keep the existing utility functions: generateRandomPhoneNumber, generateRandomBetAmount, generateFakeData)


// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}_${Date.now()}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       if (window.innerWidth < 768) {
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     updateData();
//     const interval = setInterval(updateData, 5000);
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <AnimatePresence initial={false}>
//               <tbody>
//                 {games.length === 0 ? (
//                   <tr>
//                     <td className="px-4 py-3 text-center" colSpan={4}>
//                       No Record Found
//                     </td>
//                   </tr>
//                 ) : (
//                   games.map((game, index) => (
//                     <motion.tr
//                       key={game._id}
//                       initial={{ opacity: 0, y: -20 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       exit={{ opacity: 0, y: 20 }}
//                       transition={{ duration: 0.3 }}
//                       className={`${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       <td>{new Date(game.date).toDateString()}</td>
//                       <td>
//                         <motion.span
//                           initial={{ scale: 1, textShadow: "0 0 0px rgba(255,255,0,0)" }}
//                           animate={{
//                             scale: [1, 1.05, 1],
//                             textShadow: [
//                               "0 0 0px rgba(255,255,0,0)",
//                               "0 0 10px rgba(255,255,0,0.8)",
//                               "0 0 0px rgba(255,255,0,0)"
//                             ]
//                           }}
//                           transition={{
//                             duration: 2,
//                             repeat: Infinity,
//                             repeatType: "reverse"
//                           }}
//                           className="font-bold text-yellow-400 inline-block"
//                         >
//                           {currency} {game.winningAmount.toLocaleString()}
//                         </motion.span>
//                       </td>
//                       <td>{`${game.phoneNumber.slice(
//                         0,
//                         6
//                       )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                       <td
//                         className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                           index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                         }`}
//                       >
//                         {game.status}
//                       </td>
//                     </motion.tr>
//                   ))
//                 )}
//               </tbody>
//             </AnimatePresence>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;


// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// // ... (keep the existing utility functions: generateRandomPhoneNumber, generateRandomBetAmount, generateFakeData)

// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}_${Date.now()}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       if (window.innerWidth < 768) {
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     updateData();
//     const interval = setInterval(updateData, 5000);
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <AnimatePresence initial={false}>
//               <tbody>
//                 {games.length === 0 ? (
//                   <tr>
//                     <td className="px-4 py-3 text-center" colSpan={4}>
//                       No Record Found
//                     </td>
//                   </tr>
//                 ) : (
//                   games.map((game, index) => (
//                     <motion.tr
//                       key={game._id}
//                       initial={{ opacity: 0, y: -20 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       exit={{ opacity: 0, y: 20 }}
//                       transition={{ duration: 0.3 }}
//                       className={`${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       <td>{new Date(game.date).toDateString()}</td>
//                       <td>
//                         <motion.span
//                           initial={{ scale: 1 }}
//                           animate={{ scale: [1, 1.1, 1] }}
//                           transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 1 }}
//                           className="font-bold text-yellow-400"
//                         >
//                           {currency} {game.winningAmount.toLocaleString()}
//                         </motion.span>
//                       </td>
//                       <td>{`${game.phoneNumber.slice(
//                         0,
//                         6
//                       )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                       <td
//                         className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                           index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                         }`}
//                       >
//                         {game.status}
//                       </td>
//                     </motion.tr>
//                   ))
//                 )}
//               </tbody>
//             </AnimatePresence>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;

// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}_${Date.now()}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       // Check if screen width is for mobile
//       if (window.innerWidth < 768) {
//         // Limit to 10 records on mobile
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     // Initial data generation
//     updateData();

//     // Set up interval to update data every 5 seconds
//     const interval = setInterval(updateData, 5000);

//     // Clean up the interval on component unmount
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <AnimatePresence initial={false}>
//               <tbody>
//                 {games.length === 0 ? (
//                   <tr>
//                     <td className="px-4 py-3 text-center" colSpan={4}>
//                       No Record Found
//                     </td>
//                   </tr>
//                 ) : (
//                   games.map((game, index) => (
//                     <motion.tr
//                       key={game._id}
//                       initial={{ opacity: 0, y: -20 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       exit={{ opacity: 0, y: 20 }}
//                       transition={{ duration: 0.3 }}
//                       className={`${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       <td>{new Date(game.date).toDateString()}</td>
//                       <td>{`${currency} ${game.winningAmount}`}</td>
//                       <td>{`${game.phoneNumber.slice(
//                         0,
//                         6
//                       )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                       <td
//                         className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                           index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                         }`}
//                       >
//                         {game.status}
//                       </td>
//                     </motion.tr>
//                   ))
//                 )}
//               </tbody>
//             </AnimatePresence>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;


// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}_${Date.now()}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       // Check if screen width is for mobile
//       if (window.innerWidth < 768) {
//         // Limit to 10 records on mobile
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     // Initial data generation
//     updateData();

//     // Set up interval to update data every 5 seconds
//     const interval = setInterval(updateData, 5000);

//     // Clean up the interval on component unmount
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               <AnimatePresence>
//                 {games.length === 0 ? (
//                   <tr>
//                     <td className="px-4 py-3 text-center" colSpan={4}>
//                       No Record Found
//                     </td>
//                   </tr>
//                 ) : (
//                   games.map((game, index) => (
//                     <motion.tr
//                       key={game._id}
//                       initial={{ opacity: 0, y: -20 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       exit={{ opacity: 0, y: 20 }}
//                       transition={{ duration: 0.3 }}
//                       className={`${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       <td>{new Date(game.date).toDateString()}</td>
//                       <td>{`${currency} ${game.winningAmount}`}</td>
//                       <td>{`${game.phoneNumber.slice(
//                         0,
//                         6
//                       )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                       <td
//                         className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                           index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                         }`}
//                       >
//                         {game.status}
//                       </td>
//                     </motion.tr>
//                   ))
//                 )}
//               </AnimatePresence>
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;

// import React, { useState, useEffect } from "react";

// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => {
//       const generatedGames = generateFakeData(
//         count,
//         countryCode,
//         firstDigits,
//         minBetAmount,
//         maxBetAmount
//       );

//       // Check if screen width is for mobile
//       if (window.innerWidth < 768) {
//         // Limit to 10 records on mobile
//         setGames(generatedGames.slice(0, 10));
//       } else {
//         setGames(generatedGames);
//       }
//     };

//     // Initial data generation
//     updateData();

//     // Set up interval to update data every 4 hours
//     const interval = setInterval(updateData, 4 * 60 * 60 * 1000);

//     // Clean up the interval on component unmount
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {games.length === 0 ? (
//                 <tr>
//                   <td className="px-4 py-3 text-center" colSpan={4}>
//                     No Record Found
//                   </td>
//                 </tr>
//               ) : (
//                 games.map((game, index) => (
//                   <tr
//                     key={game._id}
//                     className={`${
//                       index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                     }`}
//                   >
//                     <td>{new Date(game.date).toDateString()}</td>
//                     <td>{`${currency} ${game.winningAmount}`}</td>
//                     <td>{`${game.phoneNumber.slice(
//                       0,
//                       6
//                     )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                     <td
//                       className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       {game.status}
//                     </td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;


//########################################################## without mobile config 
// import React, { useState, useEffect } from "react";

// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () =>
//       setGames(
//         generateFakeData(
//           count,
//           countryCode,
//           firstDigits,
//           minBetAmount,
//           maxBetAmount
//         )
//       );
    
//     // Initial data generation
//     updateData();
    
//     // Set up interval to update data every 4 hours
//     const interval = setInterval(updateData, 4 * 60 * 60 * 1000);
    
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {games.length === 0 ? (
//                 <tr>
//                   <td className="px-4 py-3 text-center" colSpan={4}>
//                     No Record Found
//                   </td>
//                 </tr>
//               ) : (
//                 games.map((game, index) => (
//                   <tr
//                     key={game._id}
//                     className={`${
//                       index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                     }`}
//                   >
//                     <td>{new Date(game.date).toDateString()}</td>
//                     <td>{`${currency} ${game.winningAmount}`}</td>
//                     <td>{`${game.phoneNumber.slice(
//                       0,
//                       6
//                     )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                     <td
//                       className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}
//                     >
//                       {game.status}
//                     </td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;



// ///////////////////////////////////////////////////////////     fake table winner no colors

// import React, { useState, useEffect } from "react";


// const generateRandomPhoneNumber = (firstDigits) => {
//   const firstDigit =
//     firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () =>
//     Math.floor(Math.random() * 10)
//   );
//   return `${firstDigit}${remainingDigits.join("")}`;
// };

// const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (
//   count,
//   countryCode,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount
// ) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable({
//   countryCode,
//   currency,
//   firstDigits,
//   minBetAmount,
//   maxBetAmount,
//   count,
//   tableHeading,
// }) {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () =>
//       setGames(
//         generateFakeData(
//           count,
//           countryCode,
//           firstDigits,
//           minBetAmount,
//           maxBetAmount
//         )
//       );
//     updateData();
//     const interval = setInterval(updateData, 5000);
//     return () => clearInterval(interval);
//   }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1
//           className="headline mb-3 text-center text-yellow-400 font-bold text-xl"
//           >
//           {tableHeading}
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table w-full text-white md:text-sm  text-xs">
//             <thead>
//               <tr className="bg-[#061126] border-b-2 ">
//                 <th className="px-4 py-3 text-left">Date</th>
//                 <th className="px-4 py-3 text-left">Win</th>
//                 <th className="px-4 py-3 text-left">Phone</th>
//                 <th className="px-4 py-3 text-left">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {games.length === 0 ? (
//                 <tr>
//                   <td
//                     className="px-4 py-3 text-center"
//                     colSpan={4}>
//                     No Record Found
//                   </td>
//                 </tr>
//               ) : (
//                 games.map((game, index) => (
//                   <tr
//                     key={game._id}
//                     className={` ${
//                       index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126] "
//                     }`}>
//                     <td>{new Date(game.date).toDateString()}</td>
//                     <td>{`${currency} ${game.winningAmount}`}</td>
//                     <td>{`${game.phoneNumber.slice(
//                       0,
//                       6
//                     )}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                     <td
//                       className={`px-4 py-3 ${game.status.toLowerCase()} ${
//                         index % 2 === 0 ? "bg-[#071e5f]" : "bg-[#061126]"
//                       }`}>
//                       {game.status}
//                     </td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;


/*
////////////////////////////////////////////////////////     fake table winner no colors

import React, { useState, useEffect } from "react";

function DisplayTable() {
  const [games, setGames] = useState([]);

  useEffect(() => {
    // Fetch fake data initially
    const fakeData = generateFakeData(11);
    setGames(fakeData);

    // Set interval to update fake data every 5 seconds
    const interval = setInterval(() => {
      const newFakeData = generateFakeData(11);
      setGames(newFakeData);
    }, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Function to generate fake data
  const generateFakeData = (count) => {
    const fakeGames = [];
    for (let i = 0; i < count; i++) {
      const betAmount = generateRandomBetAmount();
      const randomFactor = Math.floor(Math.random() * 6) + 5; // Random number between 5 and 10
      const winningAmount = betAmount * randomFactor;
      const fakeGame = {
        _id: `fake_id_${i}`,
        date: new Date().toISOString(),
        betAmount: betAmount,
        winningAmount: winningAmount,
        phoneNumber: `233${generateRandomPhoneNumber()}`,
        status: "Win",
      };
      fakeGames.push(fakeGame);
    }
    // Sort fakeGames array in descending order based on winningAmount
    fakeGames.sort((a, b) => b.winningAmount - a.winningAmount);
    return fakeGames;
  };

  // Function to generate random phone number
  // const generateRandomPhoneNumber = () => {
  //   const digits = [];
  //   for (let i = 0; i < 7; i++) {
  //     digits.push(Math.floor(Math.random() * 10));
  //   }
  //   return digits.join('');
  // };

  // Function to generate random phone number
  const generateRandomPhoneNumber = () => {
    const firstDigits = [1, 7]; // Allowed first digits after 254
    const firstDigit =
      firstDigits[Math.floor(Math.random() * firstDigits.length)];
    const remainingDigits = [];
    for (let i = 0; i < 6; i++) {
      remainingDigits.push(Math.floor(Math.random() * 10));
    }
    return `${firstDigit}${remainingDigits.join("")}`;
  };

  // Function to generate random bet amount between 50 and 100, ending with 5 or 0
  const generateRandomBetAmount = () => {
    const minBetAmount = 20;
    const maxBetAmount = 500;
    const range = maxBetAmount - minBetAmount + 1;
    const betAmount = Math.floor(Math.random() * range) + minBetAmount;
    return Math.floor(betAmount / 5) * 5; // Ensure bet amount ends with 5 or 0
  };
  return (
    <div className="col-12 col-md-7 pt-5 mb-5">
      <div className="promo pe-md-3 pe-lg-5">
        <h1 className="headline mb-3 text-center text-yellow-400 font-bold text-xl">
          Top Recent Winners
        </h1>
        <div className="cta-holder  row gx-md-3 gy-3 gy-md-0">
          <table className="table w-full text-white md:text-sm  text-xs">
            <thead>
              <tr className="bg-[#010d29] border-b-2 ">
                <th
                  scope="col"
                  className="px-4 py-3 text-left">
                  Date
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left">
                  Win
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left">
                  Phone
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {games.length < 1 ? (
                <tr>
                  <td
                    className="px-4 py-3 text-center"
                    colSpan={4}>
                    No Record Found
                  </td>
                </tr>
              ) : (
                games.map((game, index) => (
                  <tr
                    key={game._id}
                    className={` ${
                      index % 2 === 0 ? "bg-[#04286e]" : "bg-[#010d29]"
                    }`}>
                    <td className="px-4 py-3">
                      {new Date(game.date).toDateString()}
                    </td>
                    <td className="px-4 py-3">GHS {game.winningAmount}</td>
                    <td className="px-4 py-3">
                      {game.phoneNumber.substring(0, 6)}xxxx
                      {game.phoneNumber.slice(-1)}
                    </td>
                    <td
                      className={`px-4 py-3 ${game.status.toLowerCase()} ${
                        index % 2 === 0 ? "bg-[#04286e]" : "bg-[#010d29]"
                      }`}>
                      {game.status}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
 
}


export default DisplayTable;

*/